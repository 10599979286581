import React from 'react'
import { capitalizeWord } from '../helpers'

export const buildCardContentFromDict = (infoDict, parentClassNames) => {
    if (infoDict) {
        const clientInfoRender = []
        for (const [key, value] of Object.entries(infoDict)) {
            const lowerCaseKey = key
                .replace(/([a-z])([A-Z])/g, '$1 $2')
                .toLowerCase()
            const render = (
                <div className="card-summary-single" key={key}>
                    <p className="card-summary-other-key">
                        {capitalizeWord(lowerCaseKey)} :
                    </p>
                    <p className="card-summary-other-value">{value}</p>
                </div>
            )
            clientInfoRender.push(render)
        }
        return <div className={parentClassNames}>{clientInfoRender}</div>
    }
}
