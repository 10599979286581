import { t } from 'i18next'
import { constants } from './constants'

const nameRegex = /^[a-zA-ZÀ-ÿ][a-zA-ZÀ-ÿ .'-]{0,28}[a-zA-ZÀ-ÿ ]$/
const queryRegex = /[^\w\sÀ-ÿ'-]/g

export const clientDetailsFormRequiredFields = [
    'lastname',
    'firstname',
    'gender',
    'age',
    'phone',
    'phoneOwner'
]

const countryCheck = (country: string): { message: string; regex: RegExp } => {
    type CountryConfigType = Record<string, { message: string; regex: RegExp }>
    const countryConfig: CountryConfigType = {
        Mali: {
            message: t('enrollment.validation.phoneMali'),
            regex: /^[0-9]{8}$/
        },
        Senegal: {
            message: t('enrollment.validation.phoneSenegal'),
            regex: /^[7][0-9]{8}$/
        },
        'Cote D\'Ivoire': {
          message: t('enrollment.validation.phoneCoteDivoire'),
          regex: /^0[1,5,7][0-9]{8}$/
        }
    }
    return countryConfig[country as string]
}

export const minimalValidationRules = (args: Record<string, unknown> = {}) => {
    return {
        lastname: [
            (value: string | boolean) => {
                if (!value) {
                    return t('emptyError')
                }
                if (!nameRegex.test(value as string)) {
                    return t('enrollment.validation.name')
                }
                return undefined
            }
        ],
        firstname: [
            (value: string | boolean) => {
                if (!value) {
                    return t('emptyError')
                }
                if (!nameRegex.test(value as string)) {
                    return t('enrollment.validation.firstname')
                }
                return undefined
            }
        ],
        phone: [
            (value: string | boolean) => {
                if (!args.country) return undefined
                const { message, regex } = countryCheck(args.country as string)
                if (!value) {
                    return t('emptyError')
                }
                if (!regex.test(value as string)) {
                    return message
                }
                return undefined
            }
        ]
    }
}

type ValidationRule = (value: string | boolean) => string | undefined

export const validationRules = (args: Record<string, unknown> = {}): Record<string, ValidationRule[]> => {
    return {
        lastname: [
            (value: string | boolean) => {
                if (!value) {
                    return t('emptyError')
                }
                if (!nameRegex.test(value as string)) {
                    return t('enrollment.validation.name')
                }
                return undefined
            }
        ],
        firstname: [
            (value: string | boolean) => {
                if (!value) {
                    return t('emptyError')
                }
                if (!nameRegex.test(value as string)) {
                    return t('enrollment.validation.firstname')
                }
                return undefined
            }
        ],
        gender: [
            (value: string | boolean) => {
                if ((!value || value === '------') && !args.sex) {
                    return t('enrollment.validation.mandatoryValue')
                }
                return undefined
            }
        ],
        age: [
            (value: string | boolean) => {
                const ageRegex = /(^1[5-9]$|^[2-9]\d$|1[01]\d$|^120$)/
                if (!value) {
                    return t('emptyError')
                }
                if (!ageRegex.test(value as string)) {
                    return t('enrollment.validation.age')
                }
                return undefined
            }
        ],
        phone: [
            (value: string | boolean) => {
                if (!args.country) return undefined
                const { message, regex } = countryCheck(args.country as string)
                if (!value) {
                    return t('emptyError')
                }
                if (!regex.test(value as string)) {
                    return message
                }
                return undefined
            }
        ],
        phone2: [
            (value: string | boolean) => {
                if (!args.country) return undefined
                const { message, regex } = countryCheck(args.country as string)
                if (
                    value &&
                    (value as string).length > 0 &&
                    !regex.test(value as string)
                ) {
                    return message
                }
                return undefined
            }
        ],
        mobileMoneyPhone: [
            (value: string | boolean) => {
                if (!args.country) return undefined
                const { message, regex } = countryCheck(args.country as string)
                if (
                    value &&
                    (value as string).length > 0 &&
                    value !== 'null' &&
                    !regex.test(value as string)
                ) {
                    return message
                }
                return undefined
            }
        ],
        phoneOwner: [
            (value: string | boolean) => {
                if (!value && !args.phoneOwner) {
                    return t('emptyError')
                }
            }
        ],
        otherNumberOwner: [
            (value: string | boolean) => {
                if (!args.phoneOwner) return undefined
                if (args.phoneOwner === 'Other' && !value) {
                    return t('emptyError')
                }
            }
        ]
    }
}

export const packageValidationRules = (type: string) => {
    return {
        paymentMethod: [
            (value: string | boolean) => {
                if (!value) {
                    return t('emptyError')
                }
            }
        ],
        voucherCode: [
            (value: string | boolean) => {
                const isVoucher =
                    type === constants.PAYMENT_OPTIONS.SCRATCH_CARD
                if (!value && isVoucher) {
                    return t('emptyError')
                }
                if (
                    isVoucher &&
                    typeof value === 'string' &&
                    value.length !== 14
                ) {
                    return t('enrollment.validation.voucher')
                }
                return undefined
            }
        ]
    }
}

export const visitValidationRules = () => {
    return {
        isAFarmer: [
            (value: string | boolean) => {
                if (!value) {
                    return t('emptyError')
                }
            }
        ],
        isFarmingThisYear: [
            (value: string | boolean) => {
                if (!value) {
                    return t('emptyError')
                }
                return undefined
            }
        ],
        willFarmNextYear: [
            (value: string | boolean) => {
                if (!value) {
                    return t('emptyError')
                }
                return undefined
            }
        ],
        wantsOtherVisit: [
            (value: string | boolean) => {
                if (!value) {
                    return t('emptyError')
                }
                return undefined
            }
        ]
    }
}

export const clientSearchValidationRule = (
    args: Record<string, unknown> = {}
) => {
    return {
        clientId: [
            (value: string | boolean) => {
                if (
                    !args.firstName &&
                    !args.lastName &&
                    !args.primaryPhone &&
                    !value
                ) {
                    return t('emptyError')
                }
            }
        ],
        lastName: [
            (value: string | boolean) => {
                if (args.firstName && !value) {
                    return t('emptyError')
                }
            }
        ],
        firstName: [
            (value: string | boolean) => {
                if (args.lastName && !value) {
                    return t('emptyError')
                }
            }
        ],
        primaryPhone: [
            (value: string | boolean) => {
                if (!args.country) return undefined
                const { message, regex } = countryCheck(args.country as string)
                if (
                    value &&
                    (value as string).length > 0 &&
                    value !== 'null' &&
                    !regex.test(value as string)
                ) {
                    return message
                }
                return undefined
            }
        ]
    }
}

export const quickSearchValidationRules = () => {
    return {
        query: [
            (value: string | undefined) => {
                if (!value) {
                    return undefined
                }
                if (queryRegex.test(value)) {
                    return t('enrollment.validation.query')
                }
                return undefined
            }
        ]
    }
}
