import { useState, useEffect } from 'react'
import connectApi from '../api/connectApi'

const offlineTime = () => {
    return new Date().toLocaleDateString('en-GB', {
        day: 'numeric',
        month: 'short',
        hour: '2-digit',
        minute: '2-digit'
    })
}

const isUserOnline = async (maxPingTime) => {
    try {
        const response = await Promise.race([
            connectApi.get('/ping-pong/'),
            new Promise((resolve, reject) =>
                setTimeout(() => reject(new Error('Timeout')), maxPingTime)
            )
        ])
        if (response?.status !== 200) throw new Error('Timeout')
        return true
    } catch (error) {
        return false
    }
}

export const useNetworkConnection = () => {
    const [online, setOnline] = useState({
        isOnline: navigator.onLine,
        lastOnline: ''
    })

    const handleInternetConnection = () => {
        if (!navigator.onLine) {
            setOnline({
                isOnline: false,
                lastOnline: offlineTime()
            })
        } else checkNetworkStatus()
    }

    const checkNetworkStatus = async () => {
        const isOnline = await isUserOnline(
            process.env.REACT_APP_MAX_PING_TIME || 5000
        )
        setOnline({
            isOnline,
            lastOnline: isOnline ? '' : offlineTime()
        })
    }

    useEffect(() => {
        window.addEventListener('online', handleInternetConnection)
        window.addEventListener('offline', handleInternetConnection)
        const intervalID = setInterval(
            checkNetworkStatus,
            process.env.REACT_APP_PING_INTERVAL_TIME || 60000
        )
        // Check network status when user refreshes the page
        checkNetworkStatus()
        return () => {
            clearInterval(intervalID)
            window.removeEventListener('online', handleInternetConnection)
            window.removeEventListener('offline', handleInternetConnection)
        }
    }, [])

    return { ...online }
}
