import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import {
    Container,
    Col,
    Row,
    TextInput,
    Password,
    Button,
    Alert,
    Spinner,
    ToggleButton
} from '../../components'
import { useOnChange, useBindDispatch } from 'hooks'
import { constants, setTrainingMode, isAuthenticated } from 'helpers'
import './login.css'
import i18n from '../../helpers/i18n'
import { useMatomo } from '@jonkoops/matomo-tracker-react'

export const Login = () => {
    const { t } = useTranslation()
    const [error, setError] = useState(false)
    const [isSpinner, setIsSpinner] = useState(false)
    const [errMsg, setErrMsg] = useState('')
    const navigate = useNavigate()
    const authenticated = isAuthenticated()
    const { inputText, onChangeHandler, resetTextInput } = useOnChange({
        username: '',
        password: ''
    })
    const { loginDispatch, navbarHandler } = useBindDispatch()
    const loginState = useSelector((store) => store.auth)
    const { pushInstruction } = useMatomo()

    const isTrainingModeOn =
        process.env.REACT_APP_CONNECT_TRAINING_MODE === 'true'

    // hide menu for login page
    useEffect(() => {
        navbarHandler(
            authenticated ? constants.SHOW_NAVBAR : constants.HIDE_NAVBAR
        )
        if (!authenticated) {
            setTrainingMode(isTrainingModeOn)
            i18n.changeLanguage('fr')
        }
    }, [])

    // don't show login page for authenticated users
    useEffect(() => {
        if (authenticated) {
            navigate('/home')
        }
    }, [])

    useEffect(() => {
        setIsSpinner(loginState.loggingIn)
        if (loginState.response?.statusCode === 200) {
            navigate('/home')
            resetTextInput()
            // show menu for logged in user
            navbarHandler(constants.SHOW_NAVBAR)
        } else if (loginState.response?.statusCode >= 400) {
            errorHandler(loginState.response.message)
        }
    }, [loginState])

    // handle login data
    const loginHandler = (e) => {
        const { username, password } = inputText
        e.preventDefault()
        if (!username || !password) {
            errorHandler(t('login.noInput'))
            return
        }
        setError(false)
        setErrMsg('')
        loginDispatch({ ...inputText })
    }

    // no input form validation
    const errorHandler = (message) => {
        setError(true)
        setErrMsg(t(message))
    }

    const handleTrainingToggle = (event) => {
        // Unfortunately we can't use the matomo hook in the setTrainingMode function
        // because it's not a react component so we have to call it in a separate function
        setTrainingMode(isTrainingModeOn)
        // change route to new training page
        // navigate()
        window.location.href = event.target.checked
            ? process.env.REACT_APP_TRAINING_URL
            : process.env.REACT_APP_PRODUCTION_BASE_URL
        switchAnalyticsEnvironment(isTrainingModeOn)
    }

    const switchAnalyticsEnvironment = (isTrainingModeOn) => {
        const matomoSiteID = isTrainingModeOn
            ? parseInt(process.env.REACT_APP_MATOMO_SITE_ID_TRAINING || '10')
            : parseInt(process.env.REACT_APP_MATOMO_SITE_ID || '10')
        pushInstruction('setSiteId', matomoSiteID)
    }

    return (
        <div>
            <Container>
                <Row>
                    <Col md={12}>
                        <div className="training-toggle">
                            <ToggleButton
                                toggleText={t('train').toUpperCase()}
                                className="toggle-training"
                                onToggle={handleTrainingToggle}
                                defaultValue={isTrainingModeOn ? 'on' : 'off'}
                            />
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col md={12}>
                        <div className="logo-parent">
                            <img
                                src="/images/logo.png"
                                alt="myagro_logo"
                                className="login-logo"
                            />
                            <h2 className="header-welcome login-welcome">
                                {t('login.welcomeHeader')}
                            </h2>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col md={12}>
                        <div className="form-parent">
                            <form onSubmit={loginHandler}>
                                <div className="formgroup">
                                    <label htmlFor="username">
                                        {t('login.username')}
                                    </label>
                                    <TextInput
                                        id="username"
                                        name="username"
                                        value={inputText.username}
                                        onChange={onChangeHandler}
                                    />
                                </div>
                                <div className="formgroup">
                                    <label htmlFor="password">
                                        {t('login.password')}
                                    </label>
                                    <Password
                                        icon
                                        id="password"
                                        name="password"
                                        value={inputText.password}
                                        onChange={onChangeHandler}
                                    />
                                </div>
                                {/* <div className="forgot-password">
                                    <Links>{t('login.forgotPassword')}</Links>
                                </div> */}
                                <div className="formgroup">
                                    <Button
                                        style="primary"
                                        size="large"
                                        className="login-button"
                                        disable={loginState.loggingIn}
                                        id="login-button"
                                    >
                                        {isSpinner ? (
                                            <Spinner size="40" />
                                        ) : (
                                            t('login.login')
                                        )}
                                    </Button>
                                </div>
                            </form>
                        </div>
                    </Col>
                </Row>
            </Container>
            {error && (
                <Alert type="floating" status={'error'}>
                    {errMsg}
                </Alert>
            )}
        </div>
    )
}
