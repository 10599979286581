import {
    ClientDetailsPayloadInterface,
    TransactionPayloadType
} from 'storeTypes'
import { groupBy } from 'lodash'
import { getVoucherMatchingClientCode } from 'datastores/Merger'
import { constants, sortHandler } from 'helpers'
import { calculateBalance } from './processClientTransactionHelpers'

type ClientInfo = {
    client_code: string
    balance: number
    smss: []
    last_voucher_date?: string
}

const getClientsFormattedTransactions = (
    clients: ClientDetailsPayloadInterface[],
    transactionsByClient: Record<string, TransactionPayloadType[]>
) =>
    groupBy(
        clients?.map((client) => ({
            balance: calculateBalance(transactionsByClient[client.client_code]),
            transactionList: sortHandler(
                transactionsByClient[client.client_code] || [],
                'date_received',
                constants.SORT_TYPE.DATE,
                constants.SORT_DIRECTION.DESCENDING
            ),
            clientCode: client.client_code
        })),
        'clientCode'
    )

export const getClientTransactionDetails = (
    { client_code: clientCode, balance }: ClientInfo,
    clients: ClientDetailsPayloadInterface[],
    transactions: TransactionPayloadType[]
) => {
    const transactionsByType = groupBy(transactions, 'type')
    const transactionsByClient = groupBy(transactions, 'client_code')
    getVoucherMatchingClientCode(transactionsByType, transactions)
    const singleClientTransactions = getClientsFormattedTransactions(
        clients || [],
        transactionsByClient
    )[clientCode]?.[0]

    const clientBalance =
        balance >= (singleClientTransactions?.balance || 0)
            ? balance
            : singleClientTransactions?.balance

    const clientTransactions = singleClientTransactions?.transactionList

    const lastPaymentDate =
        clientTransactions?.length > 0
            ? clientTransactions[0]?.date_received
            : ''

    return {
        clientBalance,
        transactionList: clientTransactions,
        lastPaymentDate
    }
}
