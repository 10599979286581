/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { Card, Spinner } from '../../components'
import { useBindDispatch, useGetStorage } from '../../hooks'
import { useTranslation } from 'react-i18next'
import { amountFormatter, constants, getConnectUser } from '../../helpers/'
import { EditIcon } from '../../assets'
import { findPermission } from '../../helpers/findPermission'

export const VillageClientPacket = ({
    goalItems,
    modifyHandler,
    allowModification
}) => {
    const { t } = useTranslation()
    const { productListHandler } = useBindDispatch()
    const [localGoalItems, setLocalGoalItems] = useState([])
    const { delivered: goalItemDelivered } = useSelector(
        (state) => state.markDelivered
    )
    const { enrollmentPayload } = useSelector((state) => state.updatePackages)
    const { productListData, loading, preFetchLoading } = useSelector(
        (store) => store.enrollment
    )
    const { zoneSFID, country } = getConnectUser()

    const DELIVERY_STATUS = {
        [t('village.delivered')]: 'delivered',
        [t('village.preCertified')]: 'pre-certified',
        [t('village.inputCollected')]: 'delivered'
    }
    const deliveryStatusName = (deliveryStatus) => {
        return DELIVERY_STATUS[deliveryStatus]
    }

    const userPermissions = useGetStorage(constants.USER_PERMISSIONS)
    const { UPDATE_ORDER } = constants

    const canUpdatePackage = findPermission(userPermissions, UPDATE_ORDER)

    useEffect(() => {
        processGoalItems(goalItems)
    }, [goalItems])

    useEffect(() => {
        if (enrollmentPayload?.goal_items) {
            localStorage.setItem(
                constants.CLIENT_DETAIL_LOCAL_STORE,
                JSON.stringify(enrollmentPayload)
            )
        }
    }, [enrollmentPayload])

    useEffect(() => {
        // fetch the updated goal items from local storage
        const goalItems = JSON.parse(
            localStorage.getItem(constants.SINGLE_CLIENT_ORDERS_KEY)
        )
        if (goalItems) processGoalItems(goalItems)
    }, [goalItemDelivered])

    useEffect(() => {
        if (productListData?.length === 0 && !preFetchLoading) {
            productListHandler({ zoneSFID })
        }
    }, [])

    const processGoalItems = (goalItems = []) => {
        const updatedGoalItem = goalItems.map((goalItem) => {
            const newGoalItem = { ...goalItem }
            newGoalItem.deliveryStatus = ''
            if (newGoalItem?.ve_hand_off) {
                newGoalItem.deliveryStatus = t('village.inputCollected')
            } else if (newGoalItem?.delivered) {
                newGoalItem.deliveryStatus = t('village.delivered')
            } else if (newGoalItem?.pre_certified) {
                newGoalItem.deliveryStatus = t('village.preCertified')
            }

            return newGoalItem
        })

        const veDeliveredItems = updatedGoalItem.filter(
            (item) => item.ve_delivered && !item.delivered
        )
        const nonDeliveredItems = updatedGoalItem.filter(
            (item) => !item.delivered && !item.ve_delivered
        )
        const deliveredItems = updatedGoalItem.filter(
            (item) => item.delivered && item.season_active
        )

        setLocalGoalItems([
            ...veDeliveredItems,
            ...nonDeliveredItems,
            ...deliveredItems
        ])
    }

    const findProduct = (productId) => {
        const zoneProductList = productListData.find(
            (product) => product.zone_sf_id === zoneSFID
        )

        const product = zoneProductList?.product_list?.find(
            (product) => product.sf_id === productId
        )

        return product
    }

    if (loading) {
        return (
            <Spinner
                aria-label="loading-indicator"
                size="90"
                pageSpinner={true}
                fullscreen={true}
            />
        )
    }

    return (
        <div className="village-client-packet-parent">
            <Card shadow>
                <div className="village-client-details-padding">
                    <div className="client-details--header">
                        <p className="client-details--content">
                            {t('packageInformation')}
                        </p>
                        {canUpdatePackage && allowModification && (
                            <div
                                className="client-details--link"
                                onClick={modifyHandler}
                                data-testid="modify-packets"
                            >
                                <EditIcon
                                    color="#16a34a"
                                    width="15"
                                    height="15"
                                />
                                <p className="client-details--content client-details--content--link">
                                    {t('modify')}
                                </p>
                            </div>
                        )}
                    </div>
                    {localGoalItems.map(
                        (
                            {
                                product,
                                desired_units_display: desiredUnitsDisplay,
                                price,
                                deliveryStatus
                            },
                            idx
                        ) => {
                            return (
                                <div
                                    key={idx}
                                    className="client-packet--parent"
                                >
                                    <div className="client-packet--body">
                                        <h2 className="client-packet--main">
                                            {product.name}
                                        </h2>
                                        <div className="client-packet--size-price">
                                            <p className="client-packet--size">
                                                {desiredUnitsDisplay}{' '}
                                                {findProduct(product.id)?.unit}
                                            </p>
                                            <h2 className="client-packet--price">
                                                {amountFormatter(
                                                    price,
                                                    country
                                                )}
                                            </h2>
                                            {deliveryStatus && (
                                                <p
                                                    className={`card-delivery--status card-delivery--${deliveryStatusName(
                                                        deliveryStatus
                                                    )}`}
                                                    data-testid="delivery-status"
                                                >
                                                    {deliveryStatus}
                                                </p>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            )
                        }
                    )}
                </div>
            </Card>
        </div>
    )
}

VillageClientPacket.propTypes = {
    goalItems: PropTypes.array,
    modifyHandler: PropTypes.func,
    allowModification: PropTypes.bool
}
