import React, { useEffect, useRef, useState } from 'react'
import { SinglePaymentNotification } from './SinglePaymentNotification'
import {
    useBindDispatch,
    useAppSelector,
    useOnChange
} from 'hooks'
import { Col, Container, Row, Spinner, TopBar } from 'components'
import { CSSTransition } from 'react-transition-group'
import {
    constants,
    paymentStatus,
    amountFormatter,
    processDateTime,
    getConnectUser
} from 'helpers'
import { useTranslation } from 'react-i18next'
import { checkMarkIcon, closeIcon, moreDotsIcon } from 'assets'
import './notification.css'
import { NotificationFilter } from './NotificationFilter'
import { NotificationSearch } from './NotificationSearch'
import { NotificationModal } from './NotificationModal'
import { CardPaymentPayloadType } from 'storeTypes'

export const PaymentNotification = () => {
    const [animate, setAnimate] = useState(false)
    const [selectedPayment, setSelectedPayment] =
        useState<CardPaymentPayloadType>()
    const [activeFilter, setActiveFilter] = useState('all')

    const [animationList, setAnimationList] = useState(false)
    const {
        paymentNotificationHandler,
        filterPaymentNotificationHandler,
        searchPaymentNotificationHandler
    } = useBindDispatch()
    const { paymentData, loading } = useAppSelector(
        (state) => state.paymentNotification
    )
    const { country, username } = getConnectUser()

    const { inputText, onChangeHandler } = useOnChange({
        searchInput: ''
    })

    const nodeRef = useRef(null)
    const { t } = useTranslation()

    useEffect(() => {
        setAnimate(true)
        // fetch latest payment data
        paymentNotificationHandler(username)
    }, [])

    const paymentStatusConfig = {
        success: {
            icon: checkMarkIcon({ color: '#16a34a' }),
            status: t('cardPayment.successful')
        },
        offline: {
            icon: moreDotsIcon({ color: '#979797' }),
            status: t('cardPayment.pending')
        },
        failed: {
            icon: closeIcon({
                width: '28',
                height: '28',
                color: '#e85b5b'
            }),
            status: t('cardPayment.failed')
        },
        pending: {
            icon: moreDotsIcon({ color: '#979797' }),
            status: t('cardPayment.pending')
        }
    }

    const validPaymentStatus = selectedPayment?.status || ''

    const isPendingStatus =
        paymentStatus(validPaymentStatus) === 'pending'
            ? 'offline'
            : paymentStatus(validPaymentStatus)

    const noPaymentNotification = (
        <div className="no-payment-notification">
            <p>{t('cardPayment.noPayment')}</p>
        </div>
    )

    const resetSearchInput = () => {
        inputText.searchInput = ''
    }

    return (
        <>
            <div className="header-bar" id="header-bar">
                <Container>
                    <TopBar back>{t('notifications.payments')}</TopBar>
                </Container>
            </div>
            <CSSTransition
                unmountOnExit
                timeout={constants.ANIMATION_TIMEOUT}
                in={animate}
                classNames="generic"
                appear
                onEnter={() => setAnimationList(true)}
                nodeRef={nodeRef}
            >
                <div
                    className="notification--parent page-wrapper"
                    ref={nodeRef}
                >
                    <Container>
                        <Row
                            className={
                                animationList
                                    ? 'animate-show-1 animate-showed-1'
                                    : 'animate-show-1'
                            }
                        >
                            <Col md={12}>
                                <NotificationSearch
                                    searchHandler={
                                        searchPaymentNotificationHandler
                                    }
                                    inputText={inputText}
                                    onChangeHandler={onChangeHandler}
                                    setActiveFilter={setActiveFilter}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col md={12}>
                                <div
                                    className={
                                        animationList
                                            ? 'animate-show-3 animate-showed-3'
                                            : 'animate-show-3'
                                    }
                                >
                                    {
                                        <NotificationFilter
                                            filterHandler={
                                                filterPaymentNotificationHandler
                                            }
                                            resetSearchInput={resetSearchInput}
                                            activeFilter={activeFilter}
                                            setActiveFilter={setActiveFilter}
                                        />
                                    }
                                </div>
                            </Col>
                        </Row>
                        {loading ? (
                            <div className="notification--spinner-parent">
                                <Spinner
                                    data-testid="loading-indicator"
                                    size="50"
                                    pageSpinner={false}
                                    fullscreen={false}
                                />
                            </div>
                        ) : (
                            <Row
                                className={
                                    animationList
                                        ? 'animate-show-6 animate-showed-6'
                                        : 'animate-show-6'
                                }
                            >
                                <Col md={12}>
                                    <div className="notification--payment-list">
                                        {paymentData?.length > 0
                                            ? paymentData.map(
                                                  (singlePayment) => (
                                                      <SinglePaymentNotification
                                                          key={
                                                              singlePayment.uuid
                                                          }
                                                          cardNumber={
                                                              singlePayment.transaction_id
                                                          }
                                                          clientCode={
                                                              singlePayment.client_code
                                                          }
                                                          dateTime={
                                                              singlePayment.date_received
                                                          }
                                                          amount={
                                                              parseInt(
                                                                  singlePayment.amount ||
                                                                      '0'
                                                              ) > 0
                                                                  ? amountFormatter(
                                                                        singlePayment.amount,
                                                                        country
                                                                    )
                                                                  : ''
                                                          }
                                                          icon={
                                                              paymentStatusConfig[
                                                                  paymentStatus(
                                                                      singlePayment.status ||
                                                                          ''
                                                                  )
                                                              ].icon
                                                          }
                                                          status={paymentStatus(
                                                              singlePayment.status ||
                                                                  ''
                                                          )}
                                                          onClick={() =>
                                                              setSelectedPayment(
                                                                  singlePayment
                                                              )
                                                          }
                                                          formattedDateTime={
                                                              processDateTime(
                                                                  singlePayment.date_received
                                                              ) || ''
                                                          }
                                                      />
                                                  )
                                              )
                                            : noPaymentNotification}
                                    </div>
                                </Col>
                            </Row>
                        )}
                    </Container>
                </div>
            </CSSTransition>
            {selectedPayment && (
                <NotificationModal
                    processedStatus={isPendingStatus || ''}
                    cardNumber={selectedPayment.transaction_id}
                    dateTime={selectedPayment.date_received}
                    clientCode={selectedPayment.failed_msg || ''}
                    closeModal={setSelectedPayment}
                    status={validPaymentStatus}
                    icon={
                        paymentStatusConfig[paymentStatus(validPaymentStatus)]
                            .icon
                    }
                    formattedDateTime={processDateTime(
                        selectedPayment.date_received
                    )}
                />
            )}
        </>
    )
}
