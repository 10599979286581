import { TransactionPayloadType } from './transactionsTypes'

export enum ClientSearchEnum {
    FETCH_CLIENT_SEARCH = 'fetch client search',
    FETCHING_CLIENT_SEARCH = 'fetching client search',
    CLEAR_CLIENT_SEARCH = 'clear client search'
}

export enum ClientSearchFilter {
    VILLAGE = 'village'
}

export type ClientSearchType = {
    clientId?: string
    firstName?: string
    lastName?: string
    fullName?: string
    primaryPhone?: string
}

type GoalItemProduct = {
    product: {
        name: string
    }
}

export type ClientType = {
    firstname: string
    lastname: string
    fullname: string
    phone: string
    village_id: string
    client_code: string
    recent_year_delivered: string
    all_enrollment: number
    goal_items?: GoalItemProduct[]
    username: string
    date: string
    uuid: string
    form_type: string
    duplicate: boolean
    client_status: string
    allEnrollment: number
    zone: string
    _is_deleted: boolean
    smss?: TransactionPayloadType[]
}

type ClientSearchPayloadType = {
    allClientList: ClientType[]
    searchParams: ClientSearchType
}

export interface ClientSearchInterface {
    searchValue: ClientSearchType
    villageIds?: string[]
}

type FetchClientSearchType = {
    type: ClientSearchEnum.FETCH_CLIENT_SEARCH
    payload: ClientSearchPayloadType
}

type FetchingClientSearchType = {
    type: ClientSearchEnum.FETCHING_CLIENT_SEARCH
}

type ClearClientSearchType = {
    type: ClientSearchEnum.CLEAR_CLIENT_SEARCH
}

export type ClientSearchActionTypes =
    | FetchClientSearchType
    | FetchingClientSearchType
    | ClearClientSearchType
