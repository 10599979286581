import * as Sentry from '@sentry/react'
import { useEffect } from 'react'
import {
    createRoutesFromChildren,
    matchRoutes,
    useLocation,
    useNavigationType
} from 'react-router'

export const addWebStorageContext = async (event: Sentry.Event) => {
    if (navigator.storage && navigator.storage.estimate) {
        const { usage, quota } = await navigator.storage.estimate()

        event.contexts = {
            ...event.contexts,
            web_storage: {
                quota,
                usage
            }
        }
    }

    return event
}

export const setupSentry = ({
    dsn,
    environment = 'development',
    version
}: {
    dsn: string
    environment?: string
    version?: string
}) => {
    const webStorageContextIntegration = {
        name: 'WebStorageContext',
        processEvent: addWebStorageContext
    }

    Sentry.init({
        dsn,
        environment: environment || 'development',
        release: `connect-frontend@${version}`,
        integrations: [
            Sentry.reactRouterV6BrowserTracingIntegration({
                useEffect,
                useLocation,
                useNavigationType,
                createRoutesFromChildren,
                matchRoutes
            }),
            Sentry.replayIntegration(),
            webStorageContextIntegration
        ],

        tracesSampleRate: 1.0,
        replaysSessionSampleRate: 0.1,
        replaysOnErrorSampleRate: 1.0
    })
}
