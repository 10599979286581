import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Alert } from '../components'
import { useNetworkConnection, useAlert } from '../hooks'
import { useTranslation } from 'react-i18next'
import Nav from './Nav'
import { offlineIcon } from 'assets'
import { getConnectUser } from 'helpers'

export const NetworkNavbar = () => {
    const { isOnline } = useNetworkConnection()
    const [displayAlert, setDisplayAlert] = useAlert(2500)
    const { status } = useSelector((store) => store.navbar)
    const { username } = getConnectUser()
    const { t } = useTranslation()

    const getAlert = ({ status, message, iconText, iconType }) => {
        return (
            <Alert
                icon
                iconText={iconText}
                iconType={iconType}
                status={status}
                type="floating"
            >
                {message}
            </Alert>
        )
    }

    useEffect(() => {
        setDisplayAlert((prevState) => {
            if (!prevState && isOnline) {
                return true
            } else if (prevState === null && !isOnline) {
                return false
            } else {
                return null
            }
        })
    }, [isOnline])

    const networkAlert = (
        <div>
            {isOnline ? (
                <Alert status="success" prefixText={`(${username})`}>
                    {t('networkBarOnline')}{' '}
                    <span className="version--text">
                        {process.env.REACT_APP_APP_VERSION}
                    </span>
                </Alert>
            ) : (
                <Alert
                    status="error"
                    prefixText={`(${username})`}
                    icon
                    iconType={offlineIcon}
                    iconText={t('offline')}
                >
                    {t('networkOffline')}
                </Alert>
            )}
        </div>
    )

    const popupAlert =
        displayAlert !== null
            ? displayAlert
                ? getAlert({
                      status: 'success',
                      message: t('onlineAgain')
                  })
                : getAlert({
                      status: 'error',
                      message: t('noInternet'),
                      iconText: t('offline'),
                      iconType: offlineIcon
                  })
            : ''

    const statusConfig = {
        SHOW_FULL: (
            <>
                {networkAlert} <Nav /> {popupAlert}
            </>
        ),
        HIDE_FULL: <></>,
        HIDE_NAV: (
            <>
                {networkAlert} {popupAlert}
            </>
        )
    }

    const networkNavbarHandler = (status) => {
        return statusConfig[status]
    }

    return networkNavbarHandler(status)
}
