import {
    FlagIcon,
    OneCoinIcon,
    phoneIcon,
    RectIcon,
    SacIcon,
    ThreeCoinsIcon,
    TrophyIcon,
    TwoCoinsIcon
} from 'assets'
import React from 'react'
import { InteractionOutcomes } from 'types'
import './home.css'
import { TargetInterface } from './targetTypes'

export const Target: React.FC<TargetInterface & { filter?: string }> = ({
    targetData,
    targetOnClick
}) => {
    const {
        isCompleted = false,
        client_code: clientCode,
        desired_outcome: desiredOutcome,
        firstname,
        lastname,
        outcome,
        phone = '',
        duplicate = false,
        isFinisher
    } = targetData

    const GREEN_COLOR = '#16A34A'
    const ORANGE_COLOR = '#F4A261'
    const GREY_COLOR = '#979797'

    const outcomeConfig = ({
        outcome,
        isFinisher
    }: {
        outcome: keyof typeof InteractionOutcomes | undefined
        isFinisher?: boolean
    }) => {
        const hasMetPaymentOutcome =
            outcome === InteractionOutcomes.CARD_PAYMENT ||
            outcome === InteractionOutcomes.MOBILE_MONEY_PAYMENT

        return {
            Payment_1st: (
                <OneCoinIcon color={hasMetPaymentOutcome ? GREEN_COLOR : ''} />
            ),
            Payment_3rd: (
                <ThreeCoinsIcon
                    color={hasMetPaymentOutcome ? GREEN_COLOR : ''}
                />
            ),
            Payment_2nd: (
                <TwoCoinsIcon color={hasMetPaymentOutcome ? GREEN_COLOR : ''} />
            ),
            Payment_finisher: (
                <TrophyIcon color={isFinisher ? ORANGE_COLOR : ''} />
            ),
            Enrolled: (
                <SacIcon
                    color={
                        outcome === InteractionOutcomes.ENROLLED
                            ? GREEN_COLOR
                            : ''
                    }
                />
            )
        }
    }

    const duplicateCancelHandler = ({
        duplicate,
        outcome
    }: {
        duplicate: boolean
        outcome: keyof typeof InteractionOutcomes | undefined
    }) => {
        if (duplicate) {
            return <FlagIcon />
        }
        return outcome === InteractionOutcomes.NOT_INTERESTED ? (
            <RectIcon />
        ) : null
    }

    const isCanceledOrDuplicate =
        outcome?.toLocaleLowerCase() ===
            InteractionOutcomes.NOT_INTERESTED.toLowerCase() || duplicate

    const hasFollowUpOutcome =
        outcome?.toLowerCase() === InteractionOutcomes.FOLLOW_UP.toLowerCase()

        return (
            <div
                className={`target-list--card target-list--card-${
                    isCanceledOrDuplicate
                        ? 'canceled-duplicate'
                        : hasFollowUpOutcome
                        ? 'follow-up'
                        : isCompleted || isFinisher
                        ? 'completed'
                        : ''
                }`}
                data-testid="target"
                onClick={targetOnClick}
            >
                <div className="target-list--check-client">
                    <div className="target-list--check">
                        {duplicateCancelHandler({ duplicate, outcome }) ||
                            outcomeConfig({ outcome, isFinisher })?.[
                                desiredOutcome as keyof typeof outcomeConfig
                            ]}
                    </div>
                    <div>
                        <p className="target-list--client-type-id">
                            ID: {clientCode}
                        </p>
                        <h2 className="target-list--name">{`${firstname} ${lastname}`}</h2>
                    </div>
                </div>
                <div className="target-list--status-phone">
                    <div className="target-list--phone">
                        <a href={isCanceledOrDuplicate ? '' : `tel:${phone}`}>
                            {phoneIcon({
                                color: isCanceledOrDuplicate ? GREY_COLOR : '',
                                fillColor: isCanceledOrDuplicate
                                    ? GREY_COLOR
                                    : GREEN_COLOR,
                                width: '30',
                                height: '30'
                            })}
                        </a>
                    </div>
                </div>
            </div>
        )
}
