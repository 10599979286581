import { FootIcon, HandShakeIcon } from 'assets'
import { constants } from 'helpers'
import { useAppSelector } from 'hooks'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { TargetFilterInterface } from './targetTypes'

export const TargetFilter: React.FC<TargetFilterInterface> = ({
    filterHandler
}) => {
    const [selectedFilter, setSelectedFilter] = useState('all')
    const { t } = useTranslation()
    const { selectedFilter: storeSelectedFilter } = useAppSelector(
        (state) => state.targetList
    )
    const { TARGET_LIST_FILTERS } = constants
    const GREEN_COLOR = '#16A34A'
    const targetListFilters = [
        {
            name: t('targetFilterOption.notVisited'),
            id: TARGET_LIST_FILTERS.NOT_VISITED,
            icon: (selectedFilterVal: string, targetFilter: string) => (
                <FootIcon
                    color={
                        selectedFilterVal === targetFilter ? GREEN_COLOR : ''
                    }
                />
            )
        },
        {
            name: t('targetFilterOption.visited'),
            id: TARGET_LIST_FILTERS.VISITED,
            icon: (selectedFilterVal: string, targetFilter: string) => (
                <HandShakeIcon
                    color={
                        selectedFilterVal === targetFilter ? GREEN_COLOR : ''
                    }
                />
            )
        }
    ]

    const filterClickHandler = (selectedFilterVal: string) => {
        if (selectedFilter === selectedFilterVal) {
            filterHandler(TARGET_LIST_FILTERS.ALL)
            setSelectedFilter(TARGET_LIST_FILTERS.ALL)
            return
        }
        filterHandler(selectedFilterVal)
        setSelectedFilter(selectedFilterVal)
    }

    useEffect(() => {
        setSelectedFilter(storeSelectedFilter || TARGET_LIST_FILTERS.ALL)
    }, [])

    return (
        <div className="target--filter-parent">
            {targetListFilters.map((targetFilter) => {
                return (
                    <div
                        className="target--filter-button"
                        key={targetFilter.id}
                        onClick={() => filterClickHandler(targetFilter.id)}
                        data-testid={`target-${targetFilter.id}`}
                    >
                        {targetFilter.icon(selectedFilter, targetFilter.id)}
                        <div
                            className={`target--filter-button-name ${selectedFilter === targetFilter.id
                            ? 'target--filter-button-name--selected' : ''}`}>
                            <p>{targetFilter.name}</p>
                        </div>
                    </div>
                )
            })}
        </div>
    )
}
