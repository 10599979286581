import { InteractionOutcomes } from '../types/interactionOutcomes'

export interface HomeInterface {
    veCODE: string
    country: string
}

type HomePayloadType = {
    rankingData?: {}
    name?: string
    user_id?: number
}

export enum ClientTypes {
    CurrentClient = 'current',
    PreviousClient = 'previous'
}

export enum DesiredOutcomes {
    ENROLLED = 'Enrolled',
    PAYMENT_2ND = 'Payment_2nd',
    PAYMENT_3RD = 'Payment_3rd',
    PAYMENT_FINISHER = 'Payment_finisher'
}

export type TargetPayloadType = {
    client_status: string
    client_code?: string
    firstname?: string
    lastname?: string
    fullname?: string
    duplicate?: boolean
    reversed_fullname?: string
    desired_outcome: DesiredOutcomes
    outcome?: InteractionOutcomes
    ve_code?: string
    country_name?: string
    model_version?: string
    generation_date?: string
    targeting_start_date?: string
    targeting_end_date?: string
    uuid?: string
    isCompleted: boolean
    village_id?: string
    client_type?: ClientTypes
    phone?: string
    isFinisher?: boolean
}

export type HomeReducerStateType = {
    userData: HomePayloadType[] | []
    loading: boolean
    error: string
}

export type TargetListReducerStateType = {
    targetList: [] | TargetPayloadType[]
    loading: boolean
    processing: boolean
    error: string
    processedTargetList?: TargetPayloadType[]
    selectedFilter?: string
}

export enum HomeEnum {
    FETCHING_HOME_DATA = 'fetching home data',
    FETCH_CARD_INVENTORY = 'fetch card inventory',
    FETCH_VE_RANKING_DATA = 'fetch ve ranking data',
    FETCH_USER_DATA = 've data',
    FAIL_REQUEST = 'fail request',
    PRE_FETCHING_VE_RANKING_DATA = 'prefetch fetching ve ranking data',
    PRE_FETCHING_ENROLLMENT_FORM = 'pre fetching enrollment form',
    FETCH_ENROLLMENT_FORM = 'fetch enrollment form',
    FETCHING_TARGET_LIST = 'fetching target list',
    FETCH_TARGET_LIST = 'fetch target list',
    FAIL_TARGET_LIST_REQUEST = 'fail target list request',
    FETCH_PROCESSED_TARGET_LIST = 'fetch processed target list',
    FILTER_PROCESSED_TARGET_LIST = 'filter processed target list',
    NO_PROCESSED_TARGET_LIST = 'no processed target list'
}

type FetchingHomeDataType = {
    type: HomeEnum.FETCHING_HOME_DATA
}

type FetchVeRankingDataType = {
    type: HomeEnum.FETCH_VE_RANKING_DATA
    payload: HomePayloadType
}

type FetchUserDataType = {
    type: HomeEnum.FETCH_USER_DATA
    payload: HomePayloadType
}

type FailRequest = {
    type: HomeEnum.FAIL_REQUEST
    payload: string
}

type PreFetchingVeRankingDataType = {
    type: HomeEnum.PRE_FETCHING_VE_RANKING_DATA
}

type PreFetchingEnrollmentFormType = {
    type: HomeEnum.PRE_FETCHING_ENROLLMENT_FORM
}

type FetchEnrollmentFormType = {
    type: HomeEnum.FETCH_ENROLLMENT_FORM
    payload: HomePayloadType
}

type FetchingTargetListType = {
    type: HomeEnum.FETCHING_TARGET_LIST
}

type FetchTargetListType = {
    type: HomeEnum.FETCH_TARGET_LIST
    payload: TargetPayloadType[]
}

type FetchProcessedTargetListType = {
    type: HomeEnum.FETCH_PROCESSED_TARGET_LIST
    payload: TargetPayloadType[]
}

type FailTargetListRequestType = {
    type: HomeEnum.FAIL_TARGET_LIST_REQUEST
    payload: string
}

type FilterProcessedTargetListType = {
    type: HomeEnum.FILTER_PROCESSED_TARGET_LIST
    payload: {
        selectedFilter: string
        targetList: TargetPayloadType[]
    }
}

type NoProcessedTargetListType = {
    type: HomeEnum.NO_PROCESSED_TARGET_LIST
}

export type HomeActionType =
    | FetchingHomeDataType
    | FetchVeRankingDataType
    | FetchUserDataType
    | FailRequest
    | PreFetchingVeRankingDataType
    | PreFetchingEnrollmentFormType
    | FetchEnrollmentFormType

export type TargetListType =
    | FetchingTargetListType
    | FetchTargetListType
    | FailTargetListRequestType
    | FetchProcessedTargetListType
    | FilterProcessedTargetListType
    | NoProcessedTargetListType
