import { constants, getConnectUser } from 'helpers'

type collectionsApiEndpointsType = {
    [key: string]: ((args: string) => string) | string
}

export const collectionsApiEndpoints = (): collectionsApiEndpointsType => {
    const { veVillages } = getConnectUser()
    const villageSfId = veVillages
        .map((veVillage: { sf_id: string }) => {
            return `village=${veVillage.sf_id}`
        })
        .join('&')
    return {
        clients: `${constants.endpoints.ALL_CLIENT_LIST}?${villageSfId}`
    }
}
