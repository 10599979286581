import React from 'react'
import { Container, Row, Col, Spinner } from 'components'

type LoadingStateProps = {
    loadingText?: string
    spinnerSize?: string
}

export const PaymentLoadingSection: React.FC<LoadingStateProps> = ({
    loadingText,
    spinnerSize
}) => (
    <Container>
        <Row>
            <Col md={12}>
                <div className="loading-wrapper">
                    <p className="loading-text">{loadingText}</p>
                    <Spinner size={spinnerSize || '120'} />
                </div>
            </Col>
        </Row>
    </Container>
)
