import { useEffect, useState } from 'react'
import { CardPaymentPayloadType, CardPaymentStatus } from 'storeTypes'

const extractSuccessfulClientPayments = (
    payments: CardPaymentPayloadType[],
    clientCode: string
) =>
    payments?.filter(
        (payment) =>
            payment.client_code === clientCode &&
            [
                CardPaymentStatus.PENDING,
                CardPaymentStatus.SUCCESS,
                CardPaymentStatus.OFFLINE
            ].includes(payment.status as CardPaymentStatus)
    )

export const useGetClientPayments = (
    paymentData: CardPaymentPayloadType[] | null,
    recentCardPayments: CardPaymentPayloadType[],
    clientCode: string
) => {
    const [payments, setPayments] = useState<CardPaymentPayloadType[]>([])

    useEffect(() => {
        if (paymentData) {
            setPayments(
                extractSuccessfulClientPayments(paymentData, clientCode)
            )
        }
    }, [paymentData, recentCardPayments?.length])

    return payments || []
}
