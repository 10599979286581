import {
    GpsCoords,
    PacketFormInterface,
    ProductInfo,
    SelectedPacket
} from 'components/enrollForm/enrollFormTypes'
import { CardPaymentPayloadType } from './cardTypes'
import { ClientType } from 'storeTypes'

export enum EnrollmentEnum {
    FETCHING_ENROLLMENT_FORM = 'fetching enrollment form',
    FETCH_ENROLLMENT_FORM = 'fetch enrollment form',
    FAIL_ENROLLMENT_REQUEST = 'fail enrollment form request',
    SUBMITTING_ENROLLMENT_FORM = 'submitting enrollment form',
    SUBMIT_ENROLLMENT_FORM = 'submit enrollment form',
    FAIL_ENROLLMENT_SUBMISSION = 'fail enrollment submission',
    PRE_FETCHING_ENROLLMENT_FORM = 'pre fetching enrollment form',
    UPDATING_PACKAGES = 'updating_packages',
    UPDATE_PACKAGES_SUCCESS = 'update_packages_success',
    UPDATE_PACKAGES_FAIL = 'update_packages_fail',
    RESET_CLIENT_ORDERS = 'reset client orders',
    SUBMIT_CLIENT_DETAILS_ENROLLMENT = 'submit client details enrollment',
    SUBMITTING_CLIENT_DETAILS_ENROLLMENT = 'submitting client details enrollment',
    FAIL_CLIENT_DETAILS_ENROLLMENT = 'fail client details enrollment',
    CLIENT_DETAILS_PAYLOAD = 'client details payload',
    ENROLLMENT_PAYLOAD = 'enrollment payload',
    SAVE_CLIENTS_STATE = 'save_clients_state',
    SELECT_PRODUCT = 'select_product',
    ADD_PACKETS = 'add_packets',
    REMOVE_PACKETS = 'remove_packets',
    SAVE_PAYMENTS_STATE = 'save_payments_state'
}

export interface ClientDetailsPayloadInterface {
    [key: string]: string | boolean | undefined | number | null | Date | []
    client_id?: string
    client_code: string
    client_status?: string
    firstname?: string
    lastname?: string
    fullname?: string
    duplicate?: boolean
    zone?: string
    village?: string
    village_id?: string
    phone?: string
    phone_2?: string
    sex?: string
    age?: number
    group_leader?: boolean
    head_of_household?: boolean
    reimbursement_choice?: string
    username: string
    mobile_money_phone?: string
    phone_owner?: string
    date: string
    latitude?: number | null
    longitude?: number | null
    accuracy?: number | null
    uuid: string
    form_type: string
    villageName?: string
    zoneName?: string
    all_enrollment?: string
    smss?: []
}
export interface EnrollmentInterface {
    zoneSFID?: string
    formPayload?: {}
    clientDetailsPayload?: ClientDetailsPayloadInterface
}

export type EnrollmentPayloadType = {
    loading?: boolean
    productListData?: []
    error?: string | null
    response?: [] | string | null
    preFetchLoading?: boolean
    clientDetailsPayload?: {}
    enrollmentPayload?: ClientType
    gpsCoords?: {}
}

type FetchingEnrollmentFormType = {
    type: EnrollmentEnum.FETCHING_ENROLLMENT_FORM
}

type FetchEnrollmentFormType = {
    type: EnrollmentEnum.FETCH_ENROLLMENT_FORM
    payload: EnrollmentPayloadType
}

type FailEnrollmentRequestType = {
    type: EnrollmentEnum.FAIL_ENROLLMENT_REQUEST
    payload?: string
}

type SubmittingEnrollmentFormType = {
    type: EnrollmentEnum.SUBMITTING_ENROLLMENT_FORM
}

type SubmitEnrollmentFormType = {
    type: EnrollmentEnum.SUBMIT_ENROLLMENT_FORM
    payload?: []
}

type FailEnrollmentSubmissionType = {
    type: EnrollmentEnum.FAIL_ENROLLMENT_SUBMISSION
    payload?: string
}

type PreFetchingEnrollmentFormType = {
    type: EnrollmentEnum.PRE_FETCHING_ENROLLMENT_FORM
    payload: EnrollmentPayloadType
}

type UpdatingPackages = {
    type: EnrollmentEnum.UPDATING_PACKAGES
}
type UpdatePackagesSuccess = {
    type: EnrollmentEnum.UPDATE_PACKAGES_SUCCESS
}
type UpdatePackagesFail = {
    type: EnrollmentEnum.UPDATE_PACKAGES_FAIL
    payload?: string
}
type SubmitClientDetailsEnrollmentFormType = {
    type: EnrollmentEnum.SUBMIT_CLIENT_DETAILS_ENROLLMENT
    payload?: string
}

type SubmittingClientDetailsEnrollmentFormType = {
    type: EnrollmentEnum.SUBMITTING_CLIENT_DETAILS_ENROLLMENT
}

type FailClientDetailsEnrollmentFormType = {
    type: EnrollmentEnum.FAIL_CLIENT_DETAILS_ENROLLMENT
    payload?: string
}

type ClientDetailsPayloadType = {
    type: EnrollmentEnum.CLIENT_DETAILS_PAYLOAD
    payload?: {}
    gpsCoords?: GpsCoords
}

type EnrollmentPayload = {
    type: EnrollmentEnum.ENROLLMENT_PAYLOAD
    payload?: EnrollmentInterface
}

type ResetClientPackages = {
    type: EnrollmentEnum.RESET_CLIENT_ORDERS
}

type EnrollmentLocalStateAction = {
    type:
        | EnrollmentEnum.SAVE_CLIENTS_STATE
        | EnrollmentEnum.SELECT_PRODUCT
        | EnrollmentEnum.ADD_PACKETS
        | EnrollmentEnum.REMOVE_PACKETS
        | EnrollmentEnum.SAVE_PAYMENTS_STATE
    payload:
        | SelectedPacket
        | ClientDetailsPayloadInterface
        | ProductInfo
        | CardPaymentPayloadType
        | string
}

export type EnrollmentActionTypes =
    | FetchingEnrollmentFormType
    | FetchEnrollmentFormType
    | FailEnrollmentRequestType
    | SubmittingEnrollmentFormType
    | SubmitEnrollmentFormType
    | FailEnrollmentSubmissionType
    | PreFetchingEnrollmentFormType
    | UpdatingPackages
    | UpdatePackagesFail
    | UpdatePackagesSuccess
    | SubmitClientDetailsEnrollmentFormType
    | SubmittingClientDetailsEnrollmentFormType
    | FailClientDetailsEnrollmentFormType
    | ClientDetailsPayloadType
    | EnrollmentPayload
    | ResetClientPackages
    | EnrollmentLocalStateAction
