import React, { useState, useEffect, useRef } from 'react'
import { CSSTransition } from 'react-transition-group'
import { useNavigate } from 'react-router-dom'
import { Button, Col, Container, Row, TextInput, TopBar } from 'components'
import { useTranslation } from 'react-i18next'
import {
    useOnChange,
    useValidation,
    useBindDispatch,
    useAppSelector
} from 'hooks'
import {
    clientSearchValidationRule,
    constants,
    getConnectUser,
    VillageType
} from 'helpers'
import './clientSearch.css'

export const ClientSearch = () => {
    const [animate, setAnimate] = useState(false)
    const [animationList, setAnimationList] = useState(false)
    const nodeRef = useRef(null)
    const { t } = useTranslation()
    const [triggerSearch, setTriggerSearch] = useState(false)
    const { clientSearchHandler, clearClientSearchHandler } = useBindDispatch()
    const { searchResult, searchQuery } = useAppSelector(
        (state) => state.clientSearch
    )
    const { country, veVillages } = getConnectUser()
    const villageIds =
        veVillages?.map((village: VillageType) => village.sf_id) || []

    const { inputText, onChangeHandler } = useOnChange({
        clientId: searchQuery?.clientId || '',
        firstName: searchQuery?.firstName || '',
        lastName: searchQuery?.lastName || '',
        primaryPhone: searchQuery?.primaryPhone || ''
    })
    const navigate = useNavigate()

    useEffect(() => {
        document.title = t('clientSearch')
        // start with the top of the page
        window.scrollTo(0, 0)
        // reset the client details store
        localStorage.removeItem(constants.CLIENT_FOR_ENROLLMENT_STORE)
        setAnimate(true)
    }, [])

    useEffect(() => {
        if (inputText.firstName.length > 0 || inputText.lastName.length > 0) {
            inputText.clientId = ''
            errors.clientId = ''
        }
    }, [inputText.firstName, inputText.lastName])

    useEffect(() => {
        if (triggerSearch) {
            // store search result in local storage and redirect to search result page
            localStorage.setItem(
                constants.SEARCH_RESULT,
                JSON.stringify(searchResult)
            )
            navigate('/client-search/result')
        }
        return () => {
            // clear search result from redux store
            setTriggerSearch(false)
        }
    }, [searchResult])

    const clientSearchValidationRules = clientSearchValidationRule({
        lastName: inputText.lastName,
        firstName: inputText.firstName,
        country,
        primaryPhone: inputText.primaryPhone
    })

    const { errors, validateForm, resetError } = useValidation(
        inputText,
        clientSearchValidationRules
    )

    const searchSubmitHandler = (e: React.FormEvent) => {
        e.preventDefault()
        resetError()
        if (validateForm()) {
            clientSearchHandler({ searchValue: inputText, villageIds })
            setTriggerSearch(true)
            resetError()
        }
    }

    const closeClientSearchPage = () => {
        clearClientSearchHandler()
        navigate('/')
    }

    const clientSearchPage: JSX.Element = (
        <CSSTransition
            unmountOnExit
            timeout={constants.ANIMATION_TIMEOUT}
            in={animate}
            classNames="generic"
            appear
            onEnter={() => setAnimationList(true)}
            nodeRef={nodeRef}
        >
            <div
                className="client-search--main-parent page-wrapper"
                ref={nodeRef}
            >
                <Container>
                    <Row>
                        <Col md={12}>
                            <div>
                                <h2>{t('clientInformation')}</h2>
                            </div>
                            <div className="form-parent client-search--form-parent">
                                <form onSubmit={searchSubmitHandler}>
                                    <div
                                        className={
                                            animationList
                                                ? 'formgroup animate-show-4 animate-showed-4'
                                                : 'animate-show-4'
                                        }
                                    >
                                        <label htmlFor="clientId">
                                            {t('card.clientId')}
                                        </label>
                                        <TextInput
                                            id="clientId"
                                            name="clientId"
                                            value={inputText.clientId}
                                            onChange={onChangeHandler}
                                            errorValidation={!!errors?.clientId}
                                            type="number"
                                        />
                                        {errors?.clientId && (
                                            <p
                                                className="generic--error-message"
                                                data-testid="client-search-error"
                                            >
                                                {errors.clientId}
                                            </p>
                                        )}
                                    </div>
                                    <div
                                        className={
                                            animationList
                                                ? 'client-search--or-parent animate-show-5 animate-showed-5'
                                                : 'animate-show-5'
                                        }
                                    >
                                        <p className="client-search--or-divider">
                                            {t('or')}
                                        </p>
                                    </div>
                                    <div>
                                        <div
                                            className={
                                                animationList
                                                    ? 'formgroup animate-show-6 animate-showed-6'
                                                    : 'animate-show-6'
                                            }
                                        >
                                            <label htmlFor="lastName">
                                                {t('lastName')}
                                            </label>
                                            <TextInput
                                                id="lastName"
                                                name="lastName"
                                                value={inputText.lastName}
                                                onChange={onChangeHandler}
                                                errorValidation={
                                                    !!errors?.lastName
                                                }
                                            />
                                            {errors?.lastName && (
                                                <p
                                                    className="generic--error-message"
                                                    data-testid="client-search-error"
                                                >
                                                    {errors.lastName}
                                                </p>
                                            )}
                                        </div>
                                        <div
                                            className={
                                                animationList
                                                    ? 'formgroup animate-show-7 animate-showed-7'
                                                    : 'animate-show-7'
                                            }
                                        >
                                            <label htmlFor="firstName">
                                                {t('firstName')}
                                            </label>
                                            <TextInput
                                                id="firstName"
                                                name="firstName"
                                                value={inputText.firstName}
                                                onChange={onChangeHandler}
                                                errorValidation={
                                                    !!errors?.firstName
                                                }
                                            />
                                            {errors?.firstName && (
                                                <p
                                                    className="generic--error-message"
                                                    data-testid="client-search-error"
                                                >
                                                    {errors.firstName}
                                                </p>
                                            )}
                                        </div>
                                        <div
                                            className={
                                                animationList
                                                    ? 'formgroup animate-show-8 animate-showed-8'
                                                    : 'animate-show-8'
                                            }
                                        >
                                            <label htmlFor="primaryPhone">
                                                {t('enrollment.primaryPhone')}
                                            </label>
                                            <TextInput
                                                id="primaryPhone"
                                                name="primaryPhone"
                                                value={inputText.primaryPhone}
                                                onChange={onChangeHandler}
                                                errorValidation={
                                                    !!errors?.primaryPhone
                                                }
                                            />
                                            {errors?.primaryPhone && (
                                                <p
                                                    className="generic--error-message"
                                                    data-testid="client-search-error"
                                                >
                                                    {errors.primaryPhone}
                                                </p>
                                            )}
                                        </div>
                                    </div>
                                    <div
                                        className={
                                            animationList
                                                ? 'formgroup animate-show-8 animate-showed-8'
                                                : 'animate-show-8'
                                        }
                                    >
                                        <Button
                                            style="primary"
                                            size="large"
                                            className="next-search-button"
                                            id="next-search-button"
                                        >
                                            {t('next')}
                                        </Button>
                                    </div>
                                </form>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </CSSTransition>
    )

    return (
        <>
            <div className="header-bar" id="header-bar">
                <Container>
                    <TopBar back={true} close={() => closeClientSearchPage()}>
                        {t('client')}
                    </TopBar>
                </Container>
            </div>
            {clientSearchPage}
        </>
    )
}
