import React from 'react'
import { ClientDetailsPayloadInterface } from 'storeTypes'

export const ClientDetails = ({
    profileIcon,
    client
}: {
    profileIcon: JSX.Element
    client: ClientDetailsPayloadInterface
}) => {
    return (
        <div className="name-abr-id">
            <div className="card-client-detail--icons">{profileIcon}</div>
            <div className="">
                <div className="card-client-detail--name-icon">
                    <h2 className="card-client-detail--name">
                        {client?.fullname}{' '}
                    </h2>
                </div>
                <p className="card-client-detail--id">
                    ID {client?.client_code}
                </p>
            </div>
        </div>
    )
}
