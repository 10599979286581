import { useState, useEffect, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import { GPSCoordinatesType } from 'helpers'
import { useGetStorage } from 'hooks'

export const GEOLOCATION_ACCURACY_THRESHOLD = parseInt(
    process.env.GEOLOCATION_ACCURACY_THRESHOLD || '20'
)
export const GEOLOCATION_WAIT_TIME_IN_SECONDS = parseInt(
    process.env.GEOLOCATION_WAIT_TIME_IN_SECONDS || '20'
)
const GEOLOCATION_TIMEOUT_IN_MS = GEOLOCATION_WAIT_TIME_IN_SECONDS * 1000

type GeolocationOptions = {
    initialCoordinates: GPSCoordinatesType
    accuracyThreshold: number
    waitTime: number
    permissionRequired: boolean
}

export const useGeolocation = ({
    initialCoordinates = {
        latitude: 0,
        longitude: 0,
        accuracy: Infinity
    },
    accuracyThreshold = GEOLOCATION_ACCURACY_THRESHOLD,
    waitTime = GEOLOCATION_WAIT_TIME_IN_SECONDS,
    permissionRequired = true
}: Partial<GeolocationOptions> = {}) => {
    const [geolocationTimer, setGeolocationTimer] = useState(waitTime)
    const [loadingGeolocation, setLoadingGeolocation] = useState(true)
    const [geolocation, setGeolocation] =
        useState<GPSCoordinatesType>(initialCoordinates)
    const navigate = useNavigate()
    const startTime = useRef(Date.now())
    const localStorageKey = 'geolocation'

    const writeGeolocation = (geolocation: GPSCoordinatesType) =>
        localStorage.setItem(localStorageKey, JSON.stringify(geolocation))

    const readGeolocation = () =>
        useGetStorage(localStorageKey) || initialCoordinates

    const clearGeolocation = () => localStorage.removeItem(localStorageKey)

    const handleSuccess = (position: {
        coords: { latitude: number; longitude: number; accuracy: number }
    }) => {
        if (
            position.coords.accuracy &&
            position.coords.accuracy < geolocation.accuracy
        ) {
            writeGeolocation({
                latitude: position.coords.latitude,
                longitude: position.coords.longitude,
                accuracy: position.coords.accuracy
            })
        }
    }

    const logError = (e: GeolocationPositionError) => {
        console.error(e)
    }

    const handleError = (e: GeolocationPositionError) => {
        if (
            e.code === GeolocationPositionError.PERMISSION_DENIED &&
            permissionRequired
        ) {
            navigate('/gpswarning', { replace: true })
        }
    }

    useEffect(() => {
        const intervalId = setInterval(() => {
            const duration = Math.round((Date.now() - startTime.current) / 1000)
            setGeolocationTimer(waitTime - duration)
            const geolocation = readGeolocation()

            if (
                (geolocation.accuracy &&
                    geolocation.accuracy <= accuracyThreshold) ||
                duration >= waitTime
            ) {
                setLoadingGeolocation(false)
                setGeolocationTimer(0)
                setGeolocation(geolocation)
                clearInterval(intervalId)
            }
        }, 1000)

        return () => {
            clearInterval(intervalId)
        }
    }, [])

    useEffect(() => {
        if ('geolocation' in navigator) {
            navigator.geolocation.getCurrentPosition(
                handleSuccess,
                handleError,
                {
                    timeout: GEOLOCATION_TIMEOUT_IN_MS
                }
            )

            const id = navigator.geolocation.watchPosition(
                handleSuccess,
                logError,
                {
                    enableHighAccuracy: true,
                    timeout: GEOLOCATION_TIMEOUT_IN_MS
                }
            )

            return () => {
                navigator.geolocation.clearWatch(id)
                clearGeolocation()
            }
        }
    }, [])

    return { geolocation, geolocationTimer, loadingGeolocation }
}
