import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { VillageSelection } from './VillageSelection'
import { useGetStorage } from '../../hooks'
import { constants, getConnectUser } from '../../helpers'
import { findPermission } from '../../helpers/findPermission'
import NoVillagePage from '../../components/noVillage'

export const Village = () => {
    const { t } = useTranslation()
    const { veVillages } = getConnectUser()
    const navigate = useNavigate()

    const { USER_PERMISSIONS, VIEW_VILLAGES } = constants

    const userPermissions = useGetStorage(USER_PERMISSIONS)
    const canViewVillages = findPermission(userPermissions, VIEW_VILLAGES)

    const villageSelectionRender = () => {
        if (veVillages?.length <= 0) {
            return <NoVillagePage />
        }
        // reset scroll position
        localStorage.setItem('scroll_pos', JSON.stringify({ scroll: 0 }))
        // reset active tab
        localStorage.setItem(
            'active_tab',
            JSON.stringify({ status: true, key: 0 })
        )
        if (veVillages?.length > 1) {
            return <VillageSelection veVillages={veVillages} />
        }
        if (!canViewVillages) {
            return navigate('/')
        }
        navigate(`/village/${veVillages[0].sf_id}`)
    }

    useEffect(() => {
        villageSelectionRender()
        // set page title
        document.title = t('village.pageTitle')
    }, [canViewVillages])

    return villageSelectionRender()
}
