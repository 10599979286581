import React, { useEffect, useState } from 'react'
import {
    clipIcon,
    homeIcon,
    profileIcon,
    notificationIcon,
    plusIcon,
    MyAgroLogo,
    QRIcon
} from '../assets'
import { Button, Menu, MenuChild } from '../components'
import { isAuthenticated, constants } from 'helpers'
import { useBindDispatch, useScreenSize, useAppSelector } from '../hooks'
import { t } from 'i18next'
import { findPermission } from '../helpers/findPermission'
import { useNavigate, useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'

const Nav = () => {
    const screenSize = useScreenSize()
    const {
        getUserPermissionHandler,
        clearClientSearchHandler,
        showWarningModalHandler
    } = useBindDispatch()
    const navigate = useNavigate()
    const location = useLocation()
    const [permissionsState, setPermissions] = useState([])
    const isMobile = screenSize.screenWidth <= 800
    const { VIEW_VILLAGES, CAN_VIEW_LOGO_BUTTON, UPDATE_CLIENT } = constants

    const { permissions } = useSelector(
        ({ userPermissions }) => userPermissions
    )

    const { isVisited } = useAppSelector((state) => state.isVisited)

    useEffect(() => {
        if (permissions) {
            setPermissions(permissions)
        }

        if (isAuthenticated()) {
            getUserPermissionHandler()
        }
    }, [permissions?.length])

    const canEnroll = findPermission(permissionsState, UPDATE_CLIENT)
    const canViewVillages = findPermission(permissionsState, VIEW_VILLAGES)
    const displayLogoButton = findPermission(
        permissionsState,
        CAN_VIEW_LOGO_BUTTON
    )
    const displayQRButton = findPermission(
        permissionsState,
        constants.CAN_USE_FARMER_ID
    )

    const canViewPaymentNotification = findPermission(
        permissionsState,
        constants.CAN_VIEW_PAYMENT_NOTIFICATION
    )

    const qrCodeButton = (
        <div className="qr-code-button--parent">
            <QRIcon />
        </div>
    )

    const clientSearchNavHandler = () => {
        clearClientSearchHandler()
        navigate(displayQRButton ? '/farmer-id' : '/client-search')
    }

    const mobileMenuConfig = [
        {
            name: t('menu.home'),
            path: 'home',
            href: 'home',
            icon: homeIcon,
            isVisible: true,
            onClickCallback: () => navigate('/home')
        },
        {
            name: t('menu.village'),
            path: 'village',
            href: 'village',
            icon: clipIcon,
            isVisible: canViewVillages,
            onClickCallback: () => navigate('/village')
        },
        {
            name: displayQRButton ? (
                qrCodeButton
            ) : displayLogoButton ? (
                <span className={'menu-btn-logo'}>
                    <MyAgroLogo />
                </span>
            ) : (
                <span className={'menu-plus'}>{plusIcon({})}</span>
            ),
            path: displayQRButton ? 'farmer-id' : 'client-search',
            href: displayQRButton ? 'farmer-id' : 'client-search',
            disableActive: true,
            onClickCallback: clientSearchNavHandler,
            isVisible: canEnroll
        },
        {
            name: t('notifications.payments'),
            path: 'notifications',
            href: 'notification/payment-notification',
            icon: notificationIcon,
            isVisible: true,
            onClickCallback: () =>
                navigate('/notification/payment-notification')
        },
        {
            name: t('menu.profile'),
            path: 'profile',
            href: 'profile',
            icon: profileIcon,
            isVisible: true,
            onClickCallback: () => navigate('/profile')
        }
    ]

    const isVisitedRoute = location.pathname === '/visit'

    const visitWarningHandler = (handlerOnClickCallback) => {
        if (isVisitedRoute) {
            showWarningModalHandler(true)
            return
        }

        return handlerOnClickCallback && handlerOnClickCallback()
    }

    const mobileMenu = (
        <>
            {mobileMenuConfig.map(
                (
                    {
                        name,
                        path,
                        href,
                        icon,
                        disableActive,
                        menuOption,
                        onClickCallback,
                        isVisible
                    },
                    idx
                ) => (
                    <MenuChild
                        icon={icon}
                        href={href}
                        path={path}
                        key={idx}
                        onClickCallback={
                            !isVisited
                                ? () => visitWarningHandler(onClickCallback)
                                : onClickCallback
                        }
                        disableActive={disableActive}
                        menuOption={isVisitedRoute ? '' : menuOption}
                        isVisible={{ isVisible, idx }}
                        id={`${path}-menu`}
                    >
                        {name}
                    </MenuChild>
                )
            )}
        </>
    )

    const desktopMenuConfig = [
        {
            name: t('menu.home'),
            path: 'home',
            href: 'home',
            icon: homeIcon,
            isVisible: true,
            onClickCallback: () => navigate('/home')
        },
        {
            name: t('menu.village'),
            path: 'village',
            href: 'village',
            icon: clipIcon,
            isVisible: canViewVillages,
            onClickCallback: () => navigate('/village')
        },
        {
            name: t('notifications.payments'),
            path: 'notifications',
            href: 'notification/payment-notification',
            icon: notificationIcon,
            isVisible: canViewPaymentNotification,
            onClickCallback: () =>
                navigate('/notification/payment-notification')
        },
        {
            name: t('menu.profile'),
            path: 'profile',
            href: 'profile',
            icon: profileIcon,
            isVisible: true,
            onClickCallback: () => navigate('/profile')
        },
        {
            name: (
                <Button
                    icon={plusIcon({})}
                    iconPos="left"
                    size="small"
                    style="primary"
                >
                    {t('menu.addClient')}
                </Button>
            ),
            path: 'client-search',
            href: 'client-search',
            onClickCallback: clientSearchNavHandler,
            disableActive: true,
            isVisible: canEnroll
        }
    ]

    const desktopMenu = (
        <>
            {desktopMenuConfig
                .filter((config) => config.isVisible)
                .map(
                    (
                        {
                            name,
                            path,
                            href,
                            icon,
                            disableActive,
                            onClickCallback,
                            isVisible
                        },
                        idx
                    ) => {
                        return (
                            <MenuChild
                                icon={icon}
                                href={href}
                                onClickCallback={
                                    !isVisited
                                        ? () =>
                                              visitWarningHandler(
                                                  onClickCallback
                                              )
                                        : onClickCallback
                                }
                                path={path}
                                key={idx}
                                disableActive={disableActive}
                                id={`${path}-menu`}
                                isVisible={{ isVisible, idx }}
                            >
                                {name}
                            </MenuChild>
                        )
                    }
                )}
        </>
    )

    return (
        <Menu type={isMobile ? 'mobile menu' : 'top menu'}>
            {isMobile && mobileMenu ? mobileMenu : desktopMenu}
        </Menu>
    )
}

export default Nav
