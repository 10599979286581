import React from 'react'
import { PaymentSummaryType } from './types'
import { TrophyIcon, TriangleIcon, NotAvailableIcon } from 'assets'
import { amountFormatter } from 'helpers'
import { colors } from 'styles/colors'
import CircularProgressBar from 'components/circularProgressBar/CircularProgressBar'

interface PaymentProgressProps {
    paymentSummary: PaymentSummaryType
}

export const PaymentProgress = ({ paymentSummary }: PaymentProgressProps) => {
    const {
        allEnrollment,
        formattedAllEnrollment,
        balance,
        formattedBalance,
        percentage,
        paymentLeft,
        formattedPaymentLeft
    } = paymentSummary

    if (percentage < 0) {
        return (
            <div className="client-payment--progress-container">
                <div className="client-payment--progress-header client-payment--progress-insufficient">
                    {formattedPaymentLeft}
                    <TriangleIcon color={colors.red} />
                </div>
                <CircularProgressBar
                    percentage={0}
                    incompleteLabel={amountFormatter(allEnrollment)}
                />
                <div className="client-payment--progress-negative-balance">
                    <TriangleIcon width="17" height="14" color={colors.red} />
                    {amountFormatter(balance)}
                </div>
            </div>
        )
    }

    if (percentage === 0) {
        return (
            <div className="client-payment--progress-container">
                <div className="client-payment--progress-header client-payment--progress-not-available">
                    <TrophyIcon color={colors.grey} width="18" height="18" />
                    {formattedAllEnrollment}
                </div>
                <div
                    aria-label="progress-not-available"
                    className="client-payment--progress-bar-not-available"
                >
                    <NotAvailableIcon width="84" height="84" />
                </div>
            </div>
        )
    }

    if (percentage === 100) {
        return (
            <div className="client-payment--progress-container">
                <div className="client-payment--progress-header client-payment--progress-finished">
                    {formattedBalance}
                </div>
                <CircularProgressBar
                    percentage={percentage}
                    centerIconHeight={35}
                    centerIcon={
                        <TrophyIcon
                            color={colors.white}
                            width="35"
                            height="35"
                        />
                    }
                    gaugeColor={colors.purple}
                />
            </div>
        )
    }

    if (percentage > 100) {
        return (
            <div className="client-payment--progress-container">
                <div className="client-payment--progress-header client-payment--progress-surplus">
                    {formattedBalance}
                    <TriangleIcon />
                </div>
                <CircularProgressBar
                    percentage={percentage}
                    centerIconHeight={35}
                    centerIcon={
                        <TrophyIcon
                            color={colors.white}
                            width="35"
                            height="35"
                        />
                    }
                    centerLabel={amountFormatter(allEnrollment)}
                    gaugeColor={colors.purple}
                />
            </div>
        )
    }

    return (
        <div className="client-payment--progress-container">
            <div className="client-payment--progress-header client-payment--progress-goal">
                <TrophyIcon color={colors.grey} width="18" height="18" />
                {formattedAllEnrollment}
            </div>
            <CircularProgressBar
                percentage={percentage}
                completeLabel={amountFormatter(balance)}
                incompleteLabel={amountFormatter(paymentLeft)}
            />
        </div>
    )
}
