import { PacketSelection, Button } from 'components'
import React, { Dispatch, FC, SetStateAction } from 'react'
import { ProductInfo, SelectedPacket } from './enrollFormTypes'
import { useTranslation } from 'react-i18next'

type PacketSelectionPageProps = {
    selectedProduct: ProductInfo
    selectedPackets: SelectedPacket[]
    onPacketSelection: Dispatch<SetStateAction<SelectedPacket[]>>
    onOtherPacketsClick: () => void
    handlePacketConfirmation: () => void
    continueButtonText?: string
}

export const PacketSelectionPage: FC<PacketSelectionPageProps> = ({
    selectedProduct,
    selectedPackets,
    onPacketSelection,
    handlePacketConfirmation,
    onOtherPacketsClick,
    continueButtonText
}) => {
    const { t } = useTranslation()

    return (
        <>
            <PacketSelection
                selectedProduct={selectedProduct as ProductInfo}
                handlePacketSelection={onPacketSelection}
                preSelectedPackets={selectedPackets}
            />

            {selectedPackets?.length >= 1 && (
                <div className="package-selection-footer">
                    <Button
                        className="other-packet-btn"
                        style="primary"
                        onClick={onOtherPacketsClick}
                    >
                        {t('enrollment.otherPackets')}
                    </Button>
                    <Button
                        className="package-selection-pay-btn"
                        style="primary"
                        onClick={handlePacketConfirmation}
                    >
                        {continueButtonText || t('continue')}
                    </Button>
                </div>
            )}
        </>
    )
}
