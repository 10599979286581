import React, { useRef, useEffect, useState } from 'react'
import { ClientDetailsForm } from '../../components/enrollForm/ClientDetailsForm'
import { useNavigate, useSearchParams } from 'react-router-dom'
import {
    useGetStorage,
    useOnChange,
    useValidation,
    useBindDispatch,
    useAppSelector
} from '../../hooks'
import { Alert, Col, Container, Row, TopBar } from '../../components'
import { useTranslation } from 'react-i18next'
import {
    constants,
    generateUuidv4,
    getUserLocation,
    validationRules,
    hasClientDetailsChanged,
    getConnectUser
} from '../../helpers'
import { ClientDetailsPayloadInterface, ClientStatus } from '../../storeTypes'
import { PhoneOwnerEnum } from './enrollmentTypes'
import {
    ClientDetailsFormValidationErrorType,
    ClientDetailsStateType,
    GpsCoords
} from 'components/enrollForm/enrollFormTypes'
import { useTrackTimedEvent } from 'hooks/useTrackTimedEvent'
import { ErrorIcon } from 'assets'

export const EnrollmentClientDetails = () => {
    const { trackTimedEvent } = useTrackTimedEvent()
    const ref = useRef()
    const { t } = useTranslation()
    const { clientDetailsEnrollmentHandler } = useBindDispatch()
    const [navigationURL, setNavigationURL] = useState<string>('')
    const [geoLocation, setGeoLocation] = useState<GpsCoords>({
        latitude: null,
        longitude: null,
        accuracy: null
    })
    const [showNotification, setShowNotification] = useState<boolean>(false)
    const navigate = useNavigate()
    const [searchParams] = useSearchParams()
    const { searchResult, searchQuery } = useAppSelector(
        (state) => state.clientSearch
    )
    const formType = searchParams.get('form_type') || ''
    const villageId = searchParams.get('village_id') || ''
    const nextPage = searchParams.get('next') || ''
    const missingInfo = searchParams.get('missing_info') || 'false'
    const origin = searchParams.get('client_page') || ''
    const isGPSMandatory = nextPage === '/visit'
    const clientEnrollmentData = useGetStorage(
        constants.CLIENT_FOR_ENROLLMENT_STORE
    )
    const { zoneSFID, veVillages, zoneName } = useGetStorage(
        constants.CONNECT_USER
    )

    // get village name
    const { name: villageName } = veVillages.find(
        ({ sf_id: sfId }: { sf_id: string }) => sfId === villageId
    ) || { name: '' }
    const displayOtherNumber =
        clientEnrollmentData?.phone_owner &&
            clientEnrollmentData.phone_owner === PhoneOwnerEnum.OWN_NUMBER
            ? ''
            : clientEnrollmentData?.phone_owner
    const { username, country } = getConnectUser()
    const isSearchResult = searchResult && searchResult.length > 0
    const { inputText, onChangeHandler, setTextValue } =
        useOnChange<ClientDetailsStateType>({
            lastname:
                clientEnrollmentData?.lastname ||
                (isSearchResult ? '' : searchQuery?.lastName),
            firstname:
                clientEnrollmentData?.firstname ||
                (isSearchResult ? '' : searchQuery?.firstName),
            gender: clientEnrollmentData?.sex || '',
            age: clientEnrollmentData?.age || '',
            phone:
                clientEnrollmentData?.phone ||
                (isSearchResult ? '' : searchQuery?.primaryPhone),
            phone2: clientEnrollmentData?.phone_2 || '',
            groupLeader: clientEnrollmentData?.group_leader || false,
            headOfHousehold: clientEnrollmentData?.head_of_household || false,
            reimbursementChoice:
                clientEnrollmentData?.reimbursement_choice || '',
            mobileMoneyPhone: clientEnrollmentData?.mobile_money_phone || '',
            phoneOwner: clientEnrollmentData?.phone_owner
                ? clientEnrollmentData?.phone_owner !==
                  PhoneOwnerEnum.OWN_NUMBER
                    ? PhoneOwnerEnum.OTHER
                    : PhoneOwnerEnum.OWN_NUMBER
                : '',
            otherNumberOwner: displayOtherNumber
        })

    const enrollmentValidationRules = validationRules({
        country,
        phoneOwner: inputText.phoneOwner
    })

    const { errors, validateForm, resetError } = useValidation(
        inputText,
        enrollmentValidationRules
    )

    const isFormTypeModification = formType === 'modification'
    const submitted = useRef(false)
    const navigationPathHandler = (link: string) => {
        setNavigationURL(link)
    }

    const fetchLocation = () => {
        return new Promise((resolve) => {
            getUserLocation(
                (position: { coords: GpsCoords }) => {
                    setGeoLocation({
                        latitude: position.coords.latitude,
                        longitude: position.coords.longitude,
                        accuracy: position.coords.accuracy
                    })
                },
                () => {
                    if (isGPSMandatory) {
                        navigate(
                            `/gpswarning?next=${encodeURIComponent(
                                location.pathname + location.search
                            )}`,
                            {
                                replace: true
                            }
                        )
                    }
                    resolve({})
                }
            )
        })
    }

    const handleNextPageParam = () => {
        if (nextPage) {
            setNavigationURL(nextPage)
        }
    }

    const highlightMissingInfo = () => {
        if (missingInfo === 'true') {
            validateForm()
        }
    }

    useEffect(() => {
        // start with the top of the page
        window.scrollTo(0, 0)
        isFormTypeModification
            ? (document.title = t('modifyClient'))
            : (document.title = t('registerClient'))
        if (formType !== 'modification') {
            localStorage.removeItem(constants.CLIENT_DETAIL_LOCAL_STORE)
        }
        handleNextPageParam()
        highlightMissingInfo()
    }, [])

    useEffect(() => {
        if (inputText.phoneOwner === PhoneOwnerEnum.OWN_NUMBER) {
            setTextValue({
                ...inputText,
                otherNumberOwner: displayOtherNumber
            })
        }
    }, [inputText.phoneOwner])

    useEffect(() => {
        fetchLocation()
    }, [])

    useEffect(() => {
        let timeOut: NodeJS.Timeout
        if (nextPage === '/visit' || missingInfo === 'true') {
            setShowNotification(true)
            timeOut = setTimeout(
                () => setShowNotification(false),
                process.env.REACT_APP_NOTIFICATION_TIMEOUT
                    ? parseInt(process.env.REACT_APP_NOTIFICATION_TIMEOUT)
                    : constants.DEFAULT_FEEDBACK_MODAL_DURATION
            )
        }
        return () => clearTimeout(timeOut)
    }, [nextPage])

    const clientDetailsSubmitHandler = (e: React.FormEvent) => {
        e.preventDefault()
        resetError()

        /**
         * if form type is enrollment
         * the client code and client id are gotten from the blank id generator
         * in the clientDetailsEnrollmentHandler action and the payload is updated
         * to capture the new client code and client id
         */
        if (validateForm()) {
            // Add UUID to the paylaod
            const { latitude, longitude, accuracy } = geoLocation
            const uuid = generateUuidv4()
            const {
                lastname,
                firstname,
                age,
                phone,
                gender,
                groupLeader,
                headOfHousehold,
                mobileMoneyPhone,
                phone2,
                reimbursementChoice,
                phoneOwner,
                otherNumberOwner
            } = inputText
            const payload: ClientDetailsPayloadInterface = {
                lastname,
                firstname,
                fullname: `${firstname} ${lastname}`,
                age,
                phone,
                group_leader: groupLeader,
                head_of_household: headOfHousehold,
                mobile_money_phone: mobileMoneyPhone,
                phone_2: phone2,
                reimbursement_choice: reimbursementChoice,
                uuid,
                client_code: clientEnrollmentData?.client_code,
                date: new Date().toISOString(),
                form_type: formType,
                sex: gender,
                username,
                village: villageId,
                village_id: villageId,
                phone_owner:
                    phoneOwner !== PhoneOwnerEnum.OWN_NUMBER
                        ? otherNumberOwner
                        : phoneOwner,
                zone: isFormTypeModification
                    ? clientEnrollmentData.zone
                    : zoneSFID,
                zoneName,
                villageName,
                client_status:
                    formType !== 'modification'
                        ? ClientStatus.POTENTIAL
                        : clientEnrollmentData.client_status,
                latitude,
                longitude,
                accuracy
            }

            // Only submit form if the client details have been modified
            if (hasClientDetailsChanged(payload, clientEnrollmentData)) {
                clientDetailsEnrollmentHandler(
                    { clientDetailsPayload: payload },
                    geoLocation
                )
            }

            submitted.current = true

            // fetch local client details
            const localClientDetails = JSON.parse(
                localStorage.getItem(
                    constants.CLIENT_DETAIL_LOCAL_STORE
                ) as string
            )
            const updatedClientDetails = {
                ...localClientDetails,
                ...payload,
                fullname: `${payload.firstname} ${payload.lastname}`,
                village: payload.villageName,
                zone: zoneName
            }
            /**
             * During enrollment the client code and sfid comes from the blank id generator
             * The process of calling the blankId generator happens in the enrollment action
             * This is where the client code and sfid is updated in the local storage for the client for enrollment
             */
            localStorage.setItem(
                constants.CLIENT_FOR_ENROLLMENT_STORE,
                JSON.stringify(updatedClientDetails)
            )
            if (formType === 'modification') {
                updatedClientDetails.zone = payload.zone
                localStorage.setItem(
                    constants.CLIENT_DETAIL_LOCAL_STORE,
                    JSON.stringify(updatedClientDetails)
                )
            }
            if (!navigationURL) {
                return navigate(-1)
            }
            if (navigationURL === '/visit') {
                localStorage.setItem(
                    constants.CLIENT_FOR_VISIT_STORE,
                    JSON.stringify({
                        clientCode: payload.client_code,
                        villageId: payload.village,
                        clientName: payload.firstname,
                        origin:
                            payload.form_type === 'modification'
                                ? origin ===
                                    constants.CLIENT_TYPES.CURRENT_CLIENT
                                    ? constants.ORIGIN_CLIENT_DETAIL
                                    : constants.ORIGIN_PREV_CLIENT_DETAIL
                                : constants.ORIGIN_ENROLLMENT
                    })
                )
            } else if (navigationURL === '/enrollment/package-selection') {
                localStorage.setItem(
                    constants.CLIENT_FOR_ENROLLMENT_STORE,
                    JSON.stringify({
                        ...payload,
                        balance: clientEnrollmentData?.balance,
                        form_type: 're-enrollment'
                    })
                )
            }
            navigate(navigationURL, { replace: true })
        }
    }

    const handleBackNavigation = () => {
        navigate(-1)
    }

    useEffect(() => {
        return () => {
            const outcome = submitted.current
                ? constants.ANALYTICS.SUBMITTED
                : constants.ANALYTICS.NOT_SUBMITTED

            trackTimedEvent({
                category: constants.ANALYTICS.ENROLLMENT_FORM,
                action: `${constants.ANALYTICS.CLIENT_DETAILS_FORM} ${formType} ${outcome}`
            })
        }
    }, [])

    return (
        <>
            <div className="header-bar" id="header-bar">
                <Container>
                    <TopBar back={true} backNavigation={handleBackNavigation}>
                        {isFormTypeModification
                            ? t('modifyClient')
                            : t('home.toRegister')}
                    </TopBar>
                </Container>
            </div>
            <div>
                {showNotification && (
                    <Alert
                        type="default"
                        status="error"
                        className="alert-extended"
                        icon={false}
                    >
                        <div>
                            <ErrorIcon />
                        </div>
                        <div>{t('clientDetails.missingData')}</div>
                    </Alert>
                )}
            </div>
            <div className="page-wrapper enrollment--client-details">
                <Container>
                    <Row>
                        <Col md={12}>
                            <form onSubmit={clientDetailsSubmitHandler}>
                                <ClientDetailsForm
                                    buttonCTAText={
                                        isFormTypeModification
                                            ? nextPage === '/visit'
                                                ? t('next')
                                                : t('modify')
                                            : t('registerClient')
                                    }
                                    clientDetailsState={inputText}
                                    handleInputChange={onChangeHandler}
                                    formGroupStep1={ref.current}
                                    formValidationError={
                                        errors as ClientDetailsFormValidationErrorType
                                    }
                                    formType={formType}
                                    phoneOwner={inputText.phoneOwner}
                                    showPhoneOwner={true}
                                    navigationPathHandler={
                                        navigationPathHandler
                                    }
                                />
                            </form>
                            {/* )} */}
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    )
}
