import { groupBy } from 'lodash'

import {
    ClientDetailsPayloadInterface,
    TransactionKind,
    TransactionPayloadType
} from 'storeTypes'

export const mergeClientsWithTransactions = (
    clients: ClientDetailsPayloadInterface[],
    transactions: TransactionPayloadType[]
) => {
    const transactionsByClientCode = groupBy(transactions, 'client_code')

    const transactionsByPaymentType = groupBy(transactions, 'type')

    getVoucherMatchingClientCode(transactionsByPaymentType, transactions)

    return clients.map((client: ClientDetailsPayloadInterface) => {
        const { client_code: clientCode } = client

        if (transactionsByClientCode[clientCode]) {
            client.smss = transactionsByClientCode[clientCode] as []
        }

        return client
    })
}

export const getVoucherMatchingClientCode = (
    transactionsByPaymentType: Record<string, TransactionPayloadType[]>,
    transactions: TransactionPayloadType[] = []
) => {
    transactions?.forEach((transaction: TransactionPayloadType) => {
        if (transaction.type === TransactionKind.PAYMENT_MODIFICATION) {
            const transactionMatch = (
                transactionsByPaymentType[
                    TransactionKind.VOUCHER_PAYMENT_REVERSAL
                ] || []
            ).find(
                (item) =>
                    item.voucher_code?.split('-R')[0] ===
                    transaction.voucher_code?.split('-')[0]
            )
            const isNotClientCodeSame =
                transaction.client_code !== transactionMatch?.client_code

            if (transactionMatch && isNotClientCodeSame) {
                transaction.matchingClientCode = transactionMatch?.client_code
                transactionMatch.matchingClientCode = transaction.client_code
            } else {
                transaction.matchingClientCode = ''
            }

            transaction.voucher_code = transaction?.voucher_code?.split(
                `-${transaction.client_code}`
            )[0]
        }
    })
}
