import {
    CardEnum,
    CardActionTypes,
    CardPayloadType,
    CardPaymentEnum,
    CardPaymentActionType,
    CardPaymentPayloadType
} from '../storeTypes'

export const searchClientListIndexReducer = (
    state: CardPayloadType = { searchedClient: [] },
    action: CardActionTypes
): CardPayloadType => {
    switch (action.type) {
        case CardEnum.FETCH_CARD_CLIENT_LIST: {
            const { payload, clientCode } = action
            const filteredClientData = payload.storedData.filter((clientData) =>
                clientData.client_code.includes(clientCode)
            )
            return {
                searchedClient: filteredClientData as []
            }
        }
        case CardEnum.CLEAR_CARD_CLIENT_LIST:
            return {
                searchedClient: []
            }

        default:
            return state
    }
}

export const cardPaymentReducer = (
    state: CardPayloadType = {
        cardPaymentResponse: '',
        recentCardPayments: []
    },
    action: CardPaymentActionType
): CardPayloadType => {
    switch (action.type) {
        case CardPaymentEnum.SENDING_CARD_PAYMENT:
            return {
                ...state,
                loading: true,
                error: ''
            }
        case CardPaymentEnum.SENT_CARD_PAYMENT:
            return {
                ...state,
                loading: false,
                cardPaymentResponse: action.payload,
                recentCardPayments: [
                    ...(state.recentCardPayments as CardPaymentPayloadType[]),
                    { ...action.payment } as CardPaymentPayloadType
                ],
                error: ''
            }
        case CardPaymentEnum.FAILED_CARD_PAYMENT:
            return {
                ...state,
                loading: false,
                cardPaymentResponse: '',
                error: action.payload
            }
        case CardPaymentEnum.CLEAR_CARD_PAYMENT:
            return {
                loading: false,
                cardPaymentResponse: '',
                error: ''
            }
        default:
            return state
    }
}

export const cardPaymentResultReducer = (
    state: CardPayloadType = { paymentResponse: [] },
    action: CardPaymentActionType
): CardPayloadType => {
    switch (action.type) {
        case CardPaymentEnum.FETCHING_CARD_PAYMENT_RESULT:
            return {
                ...state,
                loading: true,
                error: '',
                paymentResponse: []
            }
        case CardPaymentEnum.FETCHED_CARD_PAYMENT_RESULT:
            return {
                ...state,
                loading: false,
                paymentResponse: action.payload,
                error: ''
            }
        case CardPaymentEnum.FAILED_CARD_PAYMENT_RESULT:
            return {
                ...state,
                loading: false,
                paymentResponse: [],
                error: action.payload
            }
        case CardPaymentEnum.CLEAR_CARD_PAYMENT_RESULT:
            return {
                loading: false,
                paymentResponse: [],
                error: ''
            }
        default:
            return state
    }
}
