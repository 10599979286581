import React, { useEffect, useState } from 'react'
import { Container, TopBar } from '../../components'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { constants, generateUuidv4, getConnectUser } from '../../helpers'
import {
    useOnChange,
    useScreenSize,
    useBindDispatch,
    useGetStorage
} from '../../hooks'
import './card.css'
import { useTranslation } from 'react-i18next'
import { CardPaymentPage } from 'pages/enrollment/v3/CardPaymentPage'

export const LOADING = 'loading'
export const FORM = 'form'
export const PENDING = 'pending'
export const APP_LAUNCHED = 'app_launched'
export const PAYMENT_SENT = 'payment_sent'

export const CardSale = () => {
    const { t } = useTranslation()
    const { username: veCode } = getConnectUser()

    const { village_id: villageId, client_details_type: clientDetailsType } =
        useGetStorage('client_details') || {
            village_id: '',
            client_details_type: ''
        }
    const [searchParams] = useSearchParams()
    const clientCode = searchParams.get('client-code')
    const clientType = searchParams.get('client-type')
    const village = searchParams.get('village-id')
    const voucherCode = searchParams.get('voucher-code')
    const selectedCardAmount = searchParams.get('card-amount')
    const nextUrl = searchParams.get('next')
    const { inputText } = useOnChange({
        clientCode: clientCode || '',
        cardNumber: voucherCode || '',
        cardAmount: selectedCardAmount || '-----'
    })
    const screenSize = useScreenSize()
    const navigate = useNavigate()
    const [paymentSourceState, setPaymentSourceState] = useState('')
    const [voucherCodeState, setVoucherCodeState] = useState('')
    const [voucherAmount, setVoucherAmount] = useState(0)
    const { navbarHandler, cardPaymentHandler } = useBindDispatch()

    const isMobile = screenSize.screenWidth < constants.MOBILE_SIZE

    useEffect(() => {
        if (voucherCodeState) {
            handleCardDirectPayment()
        }
    }, [voucherCodeState])

    const handleCardDirectPayment = () => {
        const paymentUuid = generateUuidv4()
        const paymentData = {
            transaction_id: voucherCodeState,
            date_received: new Date().toISOString(),
            memo: veCode,
            source: paymentSourceState,
            client_code: clientCode,
            uuid: paymentUuid,
            village
        }
        cardPaymentHandler({
            paymentData
        })

        navigate(`/village/${village}/client/${clientCode}/${clientType || ''}`)
    }

    // hide menu bar for mobile
    useEffect(() => {
        if (isMobile) {
            navbarHandler(constants.HIDE_PART_NAVBAR)
        } else {
            navbarHandler(constants.SHOW_NAVBAR)
        }
    }, [isMobile])

    const nextUrlHandler = () => {
        const packageForEnrollment = JSON.parse(
            localStorage.getItem(constants.PACKAGE_ENROLLMENT)
        )
        const { client, ...rest } = packageForEnrollment
        const updatedClient = {
            ...client,
            payment_flow: 'card payment',
            voucherCode: inputText.cardNumber,
            paymentCardAmount: inputText.cardAmount,
            paymentMethod: constants.PAYMENT_OPTIONS.SCRATCH_CARD
        }
        localStorage.setItem(
            constants.CLIENT_FOR_ENROLLMENT_STORE,
            JSON.stringify({ ...updatedClient, ...rest })
        )
        return navigate(`${nextUrl}&reload=true`, { replace: true })
    }

    const closeCardSaleHandler = (route) => {
        if (villageId && clientDetailsType !== 're-enrollment') {
            return navigate(`/village/${villageId}/client/${clientCode}`, {
                replace: true
            })
        }
        if (nextUrl) {
            nextUrlHandler()
        } else {
            const buildUrl = villageId ? route : '/'
            navigate(buildUrl)
        }
    }

    const onPaymentConfirmation = async (
        voucherCode,
        voucherAmount,
        paymentSource
    ) => {
        setVoucherCodeState(voucherCode)
        setVoucherAmount(voucherAmount)
        setPaymentSourceState(paymentSource)
    }

    return (
        <>
            <div className="header-bar" id="header-bar">
                <Container>
                    <TopBar
                        back
                        backNavigation={() => closeCardSaleHandler(-1)}
                        close={() => closeCardSaleHandler('/')}
                    >
                        {t('card.cardSale')}
                    </TopBar>
                </Container>
            </div>
            <CardPaymentPage
                voucherCode={voucherCodeState}
                voucherAmount={voucherAmount}
                onPaymentConfirmation={onPaymentConfirmation}
            />
        </>
    )
}
