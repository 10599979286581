import transactionSourceIcons from 'assets/svg/transactionSourceIcons'
import { amountFormatter } from 'helpers'
import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import {
    TransactionKind,
    TransactionPayloadType,
    TransactionSource
} from 'storeTypes'
import { PaymentSourceIcons } from './PaymentSourceIcons'
import { FilledPendingIcon } from 'assets/svg/svgs'

const { PaymentSuccessIcon } = transactionSourceIcons

type TransactionTypeSetting = Record<
    TransactionKind,
    { icon: JSX.Element; testID: string; amountColor?: string }
>

export const PaymentItem: FC<{
    transaction: TransactionPayloadType
    country: string
}> = ({ transaction, country }) => {
    const { t } = useTranslation()

    const {
        type,
        amount,
        voucher_code: voucherCode,
        source,
        memo,
        matchingClientCode
    } = transaction

    const isDelivery = type === TransactionKind.DELIVERY

    const sharedKeys = Object.values(TransactionKind).filter(
        (value) => value !== TransactionKind.PENDING
    )

    const transactionTypeSettings: TransactionTypeSetting = {
        ...sharedKeys.reduce((acc, key) => {
            acc[key] = {
                icon: <PaymentSuccessIcon />,
                testID: 'payment-success-icon',
                amountColor: amount && amount < 0 ? 'deducted-amount' : 'amount'
            }
            return acc
        }, {} as TransactionTypeSetting),
        [TransactionKind.PENDING]: {
            icon: <FilledPendingIcon />,
            testID: 'payment-pending-icon'
        }
    }

    const memoByTransactionType: Partial<Record<TransactionKind, string>> = {
        [TransactionKind.DELIVERY]: t('clientDetails.delivery'),
        [TransactionKind.AWARD]: `${t('clientDetails.award')} ${memo ?? ''}`,
        [TransactionKind.AWARD_REVERSAL]: `${t('clientDetails.award')}${
            memo
                ? ` ${memo.replace(/ ([0-9]{4}-[0-9]{2}-[0-9]{2})/, '-$1')}`
                : '-R'
        }`
    }
    return (
        <div className="payment--amount-card-id">
            {type && (
                <div data-testid={transactionTypeSettings[type].testID}>
                    {transactionTypeSettings[type].icon}
                </div>
            )}
            <div>
                <p
                    className={`payment--content payment--${
                        transactionTypeSettings[
                            type || TransactionKind.VOUCHER_PAYMENT
                        ]?.amountColor
                    }`}
                    data-testid="amount"
                >
                    {amountFormatter(
                        isDelivery ? Math.abs(amount || 0) : amount,
                        country,
                        type === TransactionKind.PENDING
                    )}
                </p>
                <p className="payment--content payment--card-id">
                    {memoByTransactionType[type as TransactionKind] ||
                        `${
                            matchingClientCode
                                ? voucherCode?.split('-R')[0]
                                : voucherCode
                        }${matchingClientCode ? `-${matchingClientCode}` : ''}`}
                </p>
            </div>
            <PaymentSourceIcons
                type={
                    (type?.toUpperCase() as TransactionKind) ||
                    TransactionKind.VOUCHER_PAYMENT
                }
                source={
                    (source?.toUpperCase() as TransactionSource) ||
                    TransactionSource.ORANGE_MONEY
                }
            />
        </div>
    )
}
