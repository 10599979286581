import { buildCardContentFromDict } from 'helpers'
import React from 'react'
import { ClientDetails } from './ClientDetails'
import { ClientDetailsPayloadInterface } from 'storeTypes'
import { Button } from 'components/button/Button'
import { ClientPhoneButton } from './ClientPhoneButton'

export const ClientFullDetailsCard = ({
    client,
    profileIcon,
    clientPhoneInfo,
    otherClientData,
    displayEditBtn,
    isClientDuplicate,
    btnTitle,
    modifyClickHandler
}: {
    profileIcon: JSX.Element
    client: ClientDetailsPayloadInterface
    clientPhoneInfo: { formatPhone: string; clientPhone: string }
    otherClientData: []
    displayEditBtn: boolean
    isClientDuplicate: boolean
    btnTitle: string
    modifyClickHandler: () => void
}) => {
    const otherClientDataRender = buildCardContentFromDict(
        otherClientData,
        ['card-client-detail--bottom'].join(' ').trim()
    )
    return (
        <div className="client-full-details">
            <ClientDetails profileIcon={profileIcon} client={client} />
            {otherClientDataRender}
            <ClientPhoneButton
                clientPhoneInfo={clientPhoneInfo}
                isClientDuplicate={isClientDuplicate}
            />
            {displayEditBtn && (
                <Button
                    className="card-client-detail--button-interactions maroon-color-button"
                    style="primary"
                    iconPos="right"
                    size="xl"
                    onClick={modifyClickHandler}
                    data-testid="modify-click-handler"
                    id="modify-btn"
                >
                    {btnTitle}
                </Button>
            )}
        </div>
    )
}
