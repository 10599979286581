import React from 'react'
import { noVillage } from '../assets'
import { useTranslation } from 'react-i18next'

type NoVillageProps = {
    text: string
}

const NoVillagePage = ({ text }: NoVillageProps) => {
    const { t } = useTranslation()
    return (
        <div className="no-village--parent" data-testid="no-village">
            {noVillage('#e85b5b')}
            <h2 className="no-village--text">
                {text || t('village.hasNoVillages')}
            </h2>
        </div>
    )
}

export default NoVillagePage
