import { filledPhone } from 'assets'
// import { Button } from 'components/button/Button'
import React from 'react'

export const ClientPhoneButton = ({
    clientPhoneInfo,
    isClientDuplicate
}: {
    clientPhoneInfo: { clientPhone: string; formatPhone: string }
    isClientDuplicate: boolean
}) => {
    return (
        <div className="client-phone">
            {filledPhone({
                color: isClientDuplicate ? '#979797' : '#16A34A'
            })}
            <a
                href={`tel:${clientPhoneInfo?.clientPhone}`}
                className={[
                    'phone-number-text',
                    `phone-number-text--${
                        isClientDuplicate ? 'duplicate' : 'normal'
                    }`
                ].join(' ')}
            >
                {clientPhoneInfo?.formatPhone}
            </a>
        </div>
    )
}
