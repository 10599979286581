import transactionSourceIcons from 'assets/svg/transactionSourceIcons'
import React, { FC } from 'react'
import { TransactionKind, TransactionSource } from 'storeTypes'

export const PaymentSourceIcons: FC<{
    type: TransactionKind
    source: TransactionSource
}> = ({ type, source }) => {
    const { OrangeMoneyPaymentIcon, WavePaymentIcon, ColoredCardPaymentIcon } =
        transactionSourceIcons

    const paymentSourceIconsSettings: Record<
        TransactionSource,
        { icon: JSX.Element; testID: string }
    > = {
        [TransactionSource.WAVE]: {
            icon: <WavePaymentIcon />,
            testID: 'wave-payment-icon'
        },
        [TransactionSource.ORANGE_MONEY]: {
            icon: <OrangeMoneyPaymentIcon />,
            testID: 'orange-payment-icon'
        }
    }
    return (
        <div>
            {type === TransactionKind.MOBILE_MONEY_PAYMENT ? (
                <div data-testid={paymentSourceIconsSettings[source].testID}>
                    {paymentSourceIconsSettings[source].icon}
                </div>
            ) : (
                <div data-testid="card-payment-icon">
                    <ColoredCardPaymentIcon />
                </div>
            )}
        </div>
    )
}
