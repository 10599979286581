import React, { useState } from 'react'
import InfiniteScroll from 'react-infinite-scroller'
import { constants } from 'helpers'

export const useInfiniteScroll = <T,>({
    filteredItems = [],
    itemsPerLoad = constants.ITEMS_PER_LOAD,
    createItemCard,
    containerClassName
}: {
    filteredItems: T[]
    itemsPerLoad?: number
    createItemCard: (item: T, index?: number) => JSX.Element
    containerClassName: string
}) => {
    const [hasMore, setHasMore] = useState(true)
    const [itemsToRender, setItemsToRender] = useState(
        Math.min(itemsPerLoad, filteredItems.length)
    )

    const loadMoreItems = () => {
        if (itemsToRender >= filteredItems.length) {
            setHasMore(false)
        } else {
            if (itemsToRender + itemsPerLoad > filteredItems.length) {
                setItemsToRender(filteredItems.length)
            } else {
                setItemsToRender(itemsToRender + itemsPerLoad)
            }
        }
    }

    const renderedItems = filteredItems && createItemCard && (
        <InfiniteScroll
            pageStart={0}
            loadMore={loadMoreItems}
            hasMore={hasMore}
            useWindow={true}
        >
            <div className={containerClassName}>
                {filteredItems
                    .slice(0, itemsToRender)
                    .map((item, index) => createItemCard(item, index))}
            </div>
        </InfiniteScroll>
    )

    const resetItems = () => {
        setItemsToRender(itemsPerLoad)
        setHasMore(true)
    }

    return {
        hasMore,
        setHasMore,
        setItemsToRender,
        loadMoreItems,
        renderedItems,
        resetItems
    }
}
