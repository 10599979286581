import { Card } from 'components/card'
// import { Col } from 'components/layout'
import React from 'react'
import { ClientDetails } from './ClientDetails'
import { ClientDetailsPayloadInterface } from 'storeTypes'

export const ClientDetailsCard = ({
    profileIcon,
    client
}: {
    profileIcon: JSX.Element
    client: ClientDetailsPayloadInterface
}) => {
    return (
        <Card shadow className={'height-100 animate-show-1 animate-showed-1'}>
            <ClientDetails profileIcon={profileIcon} client={client} />
        </Card>
    )
}
