import { useDispatch } from 'react-redux'
import { bindActionCreators } from 'redux'
import { AppDispatch } from 'store/configStore'
import {
    loginAction,
    logoutAction,
    navbarAction,
    veAction,
    villageClientListAction,
    selectVillageClientAction,
    veRankingAction,
    selectVillagePrevClientAction,
    storeVillageClientListAction,
    searchClientList,
    openVillagePageAction,
    veDepositsAction,
    getUserPermissions,
    clientDetailsEnrollmentAction,
    clientSearchAction,
    visitPostAction,
    cardPaymentAction,
    cardPaymentResultAction,
    updateSingleClientBalAndSmsAction,
    clearCardPaymentAction,
    clearCardPaymentResultAction,
    villageSelectionInfoAction,
    clearClientSearchAction,
    storeOrdersAction,
    fetchClientOrdersAction,
    clearVillageSelectionInfoAction,
    clearSingleClientDataAction,
    fetchTransactionsAction,
    sendInteractionsAction,
    fetchTargetListAction,
    processTargetListAction,
    filterTargetListAction,
    storePaymentInIndexedDbAction,
    paymentNotificationAction,
    filterPaymentNotificationAction,
    searchPaymentNotificationAction,
    clearSearchClientResultAction,
    resetClientOrdersAction,
    setIsVisitedAction,
    showWarningModalAction,
    selectedClientAction,
    displayFarmerIdModalAction,
    resetSelectVillageClientAction,
    refreshVillageClientListAction,
    setFarmerIdQrDataAction,
    handleMultipleVillagesCountAction
} from '../actions'
import {
    enrollmentAction,
    saveLocalEnrollmentStateAction,
    storeEnrollmentFormAction,
    updatePackagesAction
} from '../actions/enrollmentAction'
import { fetchInteractionsAction } from 'actions/interactionsActions'

export const useBindDispatch = () => {
    const dispatch = useDispatch<AppDispatch>()
    return bindActionCreators(
        {
            loginDispatch: loginAction,
            logoutDispatch: logoutAction,
            navbarHandler: navbarAction,
            veHomeHandler: veAction,
            productListHandler: enrollmentAction,
            storeEnrollmentFormHandler: storeEnrollmentFormAction,
            villageClientListHandler: villageClientListAction,
            storeVillageClientListHandler: storeVillageClientListAction,
            refreshVillageClientListHandler: refreshVillageClientListAction,
            selectVillageClientHandler: selectVillageClientAction,
            veRankingHandler: veRankingAction,
            selectVillagePrevClientActionHandler: selectVillagePrevClientAction,
            searchClientListHandler: searchClientList,
            openVillagePageHandler: openVillagePageAction,
            veDepositsHandler: veDepositsAction,
            getUserPermissionHandler: getUserPermissions,
            updatePackagesActionHandler: updatePackagesAction,
            clientDetailsEnrollmentHandler: clientDetailsEnrollmentAction,
            clientSearchHandler: clientSearchAction,
            visitSubmissionHandler: visitPostAction,
            cardPaymentHandler: cardPaymentAction,
            cardPaymentResultHandler: cardPaymentResultAction,
            updateSingleClientBalAndSmsHandler:
                updateSingleClientBalAndSmsAction,
            clearCardPaymentHandler: clearCardPaymentAction,
            clearCardPaymentResultHandler: clearCardPaymentResultAction,
            villageSelectionInfoHandler: villageSelectionInfoAction,
            clearClientSearchHandler: clearClientSearchAction,
            storeClientOrdersHandler: storeOrdersAction,
            fetchClientOrdersHandler: fetchClientOrdersAction,
            resetClientOrdersHandler: resetClientOrdersAction,
            clearVillageSelectionInfoHandler: clearVillageSelectionInfoAction,
            clearSingleClientDataHandler: clearSingleClientDataAction,
            transactionsHandler: fetchTransactionsAction,
            sendInteractionsActionHandler: sendInteractionsAction,
            fetchTargetListHandler: fetchTargetListAction,
            processTargetListHandler: processTargetListAction,
            fetchInteractionsHandler: fetchInteractionsAction,
            filterTargetListHandler: filterTargetListAction,
            storePaymentInIndexedDbHandler: storePaymentInIndexedDbAction,
            paymentNotificationHandler: paymentNotificationAction,
            filterPaymentNotificationHandler: filterPaymentNotificationAction,
            searchPaymentNotificationHandler: searchPaymentNotificationAction,
            clearSearchClientResultHandler: clearSearchClientResultAction,
            setIsVisitedHandler: setIsVisitedAction,
            showWarningModalHandler: showWarningModalAction,
            resetSelectVillageClientActionHandler:
                resetSelectVillageClientAction,
            saveEnrollmentLocalStateHandler: saveLocalEnrollmentStateAction,
            selectedClientHandler: selectedClientAction,
            displayFarmerIdModalHandler: displayFarmerIdModalAction,
            setFarmerIdQrDataHandler: setFarmerIdQrDataAction,
            handleMultipleVillagesCountHandler:
                handleMultipleVillagesCountAction
        },
        dispatch
    )
}
