import { TextInput } from 'components'
import React, { FC, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { ClientDetailsStateType } from 'components/enrollForm'
import { useValidation } from 'hooks'
import { getConnectUser, validationRules } from 'helpers'
import { ContinueButton } from 'components/button/ContinueButton'

interface TextFieldProps {
    attribute: keyof ClientDetailsStateType
    inputValue: ClientDetailsStateType
    onChangeHandler: (
        e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
    ) => void
    onClickHandler: VoidFunction
}

export const TextField: FC<TextFieldProps> = ({ attribute, inputValue, onChangeHandler, onClickHandler }) => {
    const { t } = useTranslation()

    const { country } = getConnectUser()

    const clientInfoValidationRules = validationRules({ country })

    const { errors, validateForm } = useValidation(
        { [attribute]: inputValue[attribute] },
        { [attribute]: clientInfoValidationRules[attribute] }
    )

    useEffect(() => {
        if (inputValue[attribute] && (inputValue[attribute] as string).length > 0) {
            validateForm()
        }
    }, [inputValue[attribute], country])

    return (
        <div>
            <p className="enrollment--input-label">{t(`enrollment.${attribute}`)}</p>
            <div className="enrollment--input enrollment-button">
                <div>
                    <TextInput
                        id={attribute}
                        name={attribute}
                        className="enrollment--input-field"
                        placeholder={t(`enrollment.${attribute}Placeholder`)}
                        onChange={onChangeHandler}
                        value={inputValue?.[attribute] as string}
                    />
                    {errors[attribute] && (
                        <p className="enrollment--input-error">
                            {errors[attribute]}
                        </p>
                    )}
                </div>
                {inputValue[attribute] && !errors[attribute] && (
                    <ContinueButton onClick={onClickHandler} />
                )}
            </div>
        </div>
    )
}
