import {
    UserPermissionsActionType,
    UserPermissionsType
} from 'storeTypes/userPermissionsTypes'
import { ActionTypes } from '../actions/ActionTypes'

export const userPermissionsReducer = (
    state: UserPermissionsType = {
        loading: false,
        permissionsError: '',
        permissions: []
    },
    action: UserPermissionsActionType
): UserPermissionsType => {
    switch (action.type) {
        case ActionTypes.FETCH_USER_PERMISSIONS:
            return {
                ...state,
                loading: false,
                permissionsError: '',
                permissions: action.payload as [string]
            }
        case ActionTypes.FAIL_USER_PERMISSIONS:
            return {
                loading: false,
                permissionsError: action.payload as string,
                permissions: []
            }
        default:
            return state
    }
}
