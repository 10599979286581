import React, { useCallback } from 'react'
import { colors } from 'styles/colors'
import './CircularProgressBar.css'

const DEFAULT_FONT_SIZE = 14
const DEFAULT_LINE_HEIGHT = 1.5
const DEFAULT_PROGRESS_BAR_SIZE = 150
const DEFAULT_ICON_HEIGHT = 35

interface CircularProgressBarProps {
    percentage: number
    size?: number
    fontSize?: number
    lineHeight?: number
    completeLabel?: React.ReactNode
    incompleteLabel?: React.ReactNode
    centerLabel?: React.ReactNode
    centerIcon?: React.ReactNode
    centerIconHeight?: number
    gaugeColor?: string
}

const CircularProgressBar = ({
    size = DEFAULT_PROGRESS_BAR_SIZE,
    fontSize = DEFAULT_FONT_SIZE,
    lineHeight = DEFAULT_LINE_HEIGHT,
    centerIconHeight = DEFAULT_ICON_HEIGHT,
    percentage,
    completeLabel,
    incompleteLabel,
    centerIcon,
    centerLabel,
    gaugeColor = colors.primary
}: CircularProgressBarProps) => {
    const maxPercentage = Math.min(percentage, 100)
    const completeBarHeight = (size * maxPercentage) / 100
    const incompleteBarHeight = size - completeBarHeight
    const labelHeight = fontSize * lineHeight

    const labelPosition = useCallback(
        (barHeight: number, labelHeight: number, padding = 0) =>
            barHeight / 2 - labelHeight / 2 + padding,
        []
    )

    const displayLabel = useCallback(
        (barHeight: number, labelHeight: number, padding = 0) =>
            barHeight > labelHeight + padding,
        []
    )

    const centerLabelPosition =
        centerLabel && centerIcon
            ? labelPosition(size, labelHeight + centerIconHeight, -6)
            : labelPosition(size, centerIconHeight, -9)

    return (
        <div
            className="circular-progressbar--parent"
            aria-label="progress-bar"
            style={{
                width: size,
                height: size,
                fontSize,
                lineHeight
            }}
        >
            {(centerLabel || centerIcon) && (
                <span
                    className="circular-progressbar--label"
                    aria-label="progress-center-label"
                    style={{ bottom: centerLabelPosition }}
                >
                    {centerIcon}
                    {centerLabel}
                </span>
            )}
            {incompleteLabel &&
                displayLabel(incompleteBarHeight, labelHeight, 6) && (
                    <span
                        className="circular-progressbar--label circular-progressbar--incomplete"
                        aria-label="progress-incomplete-label"
                        style={{
                            top: labelPosition(
                                incompleteBarHeight,
                                labelHeight,
                                6
                            )
                        }}
                    >
                        {incompleteLabel}
                    </span>
                )}
            {completeLabel && displayLabel(completeBarHeight, labelHeight, 3) && (
                <span
                    className="circular-progressbar--label circular-progressbar--complete"
                    aria-label="progress-complete-label"
                    style={{
                        bottom: labelPosition(completeBarHeight, labelHeight, 3)
                    }}
                >
                    {completeLabel}
                </span>
            )}
            <span
                aria-label="progress-gauge"
                style={{
                    width: size,
                    height: `${maxPercentage}%`,
                    backgroundColor: gaugeColor
                }}
            ></span>
        </div>
    )
}

export default CircularProgressBar
