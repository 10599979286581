import { amountFormatter } from 'helpers'
import { PaymentSummaryType } from '../types'

type ClientTransactionDataType = {
    allEnrollment: number
    balance: number
    last_voucher_date?: string
}

export const buildPaymentSummaryProps = (
    { allEnrollment, balance }: ClientTransactionDataType,
    lastVoucherDate: string,
    country: string
): PaymentSummaryType => ({
    allEnrollment,
    formattedAllEnrollment: amountFormatter(allEnrollment, country),
    balance,
    formattedBalance: amountFormatter(balance, country),
    paymentLeft: allEnrollment - balance,
    formattedPaymentLeft: amountFormatter(allEnrollment - balance, country),
    percentage: parseFloat(((balance / allEnrollment) * 100).toString()),
    lastVoucherDate
})
