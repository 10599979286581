import { setTheme, constants } from '.'
import { sendMessageToWorker } from './communication'

// to be deprecated or renamed to setAppTheme
export const setTrainingMode = (trainingMode: boolean) => {
    try {
        sendMessageToWorker({ 'training mode': trainingMode })
        setTheme(
            trainingMode
                ? constants.THEME_NAMES.TRAINING
                : constants.THEME_NAMES.DEFAULT
        )
    } catch (error) {
        console.log(` [setTrainingMode]: ${error}`)
    }
}
