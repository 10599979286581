import React from 'react'
import { TransactionPayloadType } from 'storeTypes'
import { PaymentRow } from './PaymentRow'
import { isSupportedTransaction } from 'helpers'
import { useTranslation } from 'react-i18next'

type PaymentHistoryProps = {
    transactions: [string, TransactionPayloadType[]][]
    country: string
}

export const PaymentHistory: React.FC<PaymentHistoryProps> = ({
    transactions,
    country
}) => {
    const { t } = useTranslation()

    const filteredTransactions = transactions
        .map(([dateReceived, transactionList]) => [
            dateReceived,
            transactionList.filter(isSupportedTransaction)
        ])
        .filter(([, transactionList]) => transactionList.length > 0)

    if (filteredTransactions.length === 0) {
        return (
            <div className="client-payment--no-payments">
                {t('village.hasNoPayments')}
            </div>
        )
    }

    return (
        <div>
            {filteredTransactions.map(
                ([dateReceived, transactionList], idx) => (
                    <PaymentRow
                        key={idx}
                        country={country}
                        transactionList={
                            transactionList as TransactionPayloadType[]
                        }
                        dateReceived={dateReceived as string}
                    />
                )
            )}
        </div>
    )
}
