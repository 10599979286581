import React, { useEffect, useState, useRef } from 'react'
import { CSSTransition } from 'react-transition-group'
import { useSelector } from 'react-redux'
import {
    Card,
    Container,
    TopBar,
    Row,
    Col,
    Spinner,
    TimedAlert
} from '../../components'
import { useBindDispatch } from '../../hooks'
import { amountFormatter, constants, getConnectUser } from '../../helpers'
import { useTranslation } from 'react-i18next'

export const Deposits = () => {
    const { t } = useTranslation()
    const [animate, setAnimate] = useState(false)
    const [animationList, setAnimationList] = useState(false)
    const nodeRef = useRef(null)
    const { username, country } = getConnectUser()
    const { veDepositsHandler } = useBindDispatch()
    const {
        loading,
        veDepositData,
        error: depositsError
    } = useSelector((store) => store.veDeposit)
    useEffect(() => {
        setAnimate(true)
        if (!veDepositData) {
            veDepositsHandler({ veCODE: username })
        }
    }, [])

    const depositsRender = (depositList) => (
        <CSSTransition
            unmountOnExit
            timeout={constants.ANIMATION_TIMEOUT}
            in={animate}
            classNames="generic"
            appear
            onEnter={() => setAnimationList(true)}
            nodeRef={nodeRef}
        >
            <div className="card-inventory-parent page-wrapper" ref={nodeRef}>
                <Container>
                    <Row>
                        <Col md={12}>
                            <Card
                                shadow
                                data-testid="card-inventory-card"
                                className={
                                    animationList
                                        ? 'animate-show-2 animate-showed-2'
                                        : 'animate-show-2'
                                }
                            >
                                {depositList.map(
                                    (
                                        {
                                            amount,
                                            reference_code: referenceCode,
                                            deposit_date: depositDate
                                        },
                                        idx
                                    ) => (
                                        <div
                                            className="card-inventory"
                                            key={idx}
                                            data-testid="card-inventory"
                                        >
                                            <div className="card-inventory--row">
                                                <p className="card-inventory--top card-inventory--date">
                                                    {t('dateMonthYear', {
                                                        date: new Date(
                                                            depositDate
                                                        )
                                                    })}
                                                </p>
                                                <h2 className="card-inventory--bottom card-inventory--amount">
                                                    {amountFormatter(
                                                        amount,
                                                        country
                                                    )}
                                                </h2>
                                            </div>
                                            <div className="card-inventory--row">
                                                <h2 className="card-inventory--bottom card-inventory--packet">
                                                    {referenceCode}
                                                </h2>
                                            </div>
                                        </div>
                                    )
                                )}
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </CSSTransition>
    )

    const depositPage = loading ? (
        <Spinner size={'90'} pageSpinner={true} />
    ) : depositsError ? (
        <TimedAlert type="floating" status="error">
            {depositsError}
        </TimedAlert>
    ) : (
        depositsRender(veDepositData.deposits)
    )

    return (
        <>
            <div className="header-bar" id="header-bar">
                <Container>
                    <TopBar back>{t('deposits.pageTitle')}</TopBar>
                </Container>
            </div>
            {depositPage}
        </>
    )
}
