import { Container, TopBar } from 'components'
import { PaymentSelection } from 'components/enrollForm/PaymentSelection'
import React from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { constants, getConnectUser } from 'helpers'
import { useBackAction, useBindDispatch } from 'hooks'
import { InteractionOutcomes } from 'types'

export const PaymentSelect = () => {
    const navigate = useNavigate()
    const [searchParams] = useSearchParams()
    const { t } = useTranslation()
    const originsWithInteraction = ['/client-search/result']

    const clientCode = searchParams.get('clientCode')
    const villageId = searchParams.get('villageId')
    const clientType = searchParams.get('clientType')
    const fromURL = searchParams.get('from')
    const clientStatus = searchParams.get('clientStatus')
    const { sendInteractionsActionHandler } = useBindDispatch()
    const { username } = getConnectUser()

    useBackAction(fromURL || '/home')

    const interactionHandler = (selectedPayment: string) => {
        if (originsWithInteraction.includes(fromURL || '')) {
            sendInteractionsActionHandler({
                client_code: clientCode || '',
                user_id: username,
                outcome:
                    selectedPayment === constants.PAYMENT_OPTIONS.SCRATCH_CARD
                        ? InteractionOutcomes.CARD_PAYMENT
                        : InteractionOutcomes.MOBILE_MONEY_PAYMENT,
                client_status: clientStatus || '',
                survey_name: constants.TARGET_LIST_SURVEY_NAME,
                survey_version: constants.TARGET_LIST_SURVEY_VERSION,
                village_id: villageId || ''
            })
        }
    }

    return (
        <>
            <div className="header-bar header-bar--transparent" id="header-bar">
                <Container>
                    <TopBar
                        back
                        backNavigation={() =>
                            fromURL ? navigate(fromURL) : navigate(-1)
                        }
                        close={() => navigate('/home')}
                        iconColor="#000000"
                        transparentTopBar
                    >
                        {t('payment')}
                    </TopBar>
                </Container>
            </div>
            <div>
                <PaymentSelection
                    totalEnrollment={0}
                    onPaymentMethodSelected={(selectedPayment) => {
                        const cardSaleParam = `client-code=${clientCode}&village-id=${villageId}`
                        const clientTypeParam = clientType
                            ? `&client-type=${clientType}`
                            : ''
                        const navigationUrl = `/cards/card-sale?${cardSaleParam}${clientTypeParam}`
                        selectedPayment ===
                        constants.PAYMENT_OPTIONS.SCRATCH_CARD
                            ? navigate(navigationUrl)
                            : ['/farmer-id', '/client-search/result'].includes(
                                  fromURL || ''
                              )
                            ? navigate(
                                  `/village/${villageId}/client/${clientCode}/${
                                      clientType || ''
                                  }`
                              )
                            : navigate(-1)
                        interactionHandler(selectedPayment)
                    }}
                    headerTitle={t('paymentTypeHeader')}
                />
            </div>
        </>
    )
}
